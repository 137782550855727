import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ROUTE as CHART_OF_THE_YEAR_PREVIEW,
  Result as PreviewResult,
} from '@api/routes/chart-of-the-year/preview/params';
import { sendApiRequest } from '@api/utils/api';

import { CDN_URL } from 'src/constants/other';

export const getChart = createAsyncThunk<
  PreviewResult,
  { isPreviewMode: boolean; year: number },
  { rejectValue: string }
>('chart-of-the-year/getChart', async (params, { rejectWithValue }) => {
  try {
    const chart = await getAnnualChart(params.year, params.isPreviewMode);

    if (chart) {
      return chart;
    } else {
      throw new Error('getChartOfTheYearError');
    }
  } catch (e) {
    console.info('error', e);
    return rejectWithValue((e as Error).message);
  }
});

export async function getAnnualChart(year: number, isPreviewMode: boolean) {
  let chart: PreviewResult | null | null = null;

  if (!isPreviewMode) {
    const chartUrl = `${CDN_URL}/annual-charts-published/${year}.json${
      process.env.ENV !== 'production' ? '?d=' + Date.now() : ''
    }`;
    const chartResponse = await fetch(chartUrl);
    chart = await chartResponse.json();
  } else {
    const response = await sendApiRequest<PreviewResult>({
      url: `${CHART_OF_THE_YEAR_PREVIEW.replace(':year', `${year}`)}?d=${Date.now()}`,
      method: 'GET',
      secure: true,
      scope: 'client',
    });

    chart = response.success ? response.data : null;
  }

  return chart;
}
