import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { REDUX_STORAGE_KEY } from 'src/constants/other';
import api from './api';
import chart from './chart';
import editorChoice from './editor-choice';
import ui from './ui';
import settings from './settings';
import chartOfTheYear from './chart-of-the-year';

// @ts-expect-error REDUX_STORAGE_KEY is provided to window by SSR
const _window: typeof globalThis & { [REDUX_STORAGE_KEY]: any } = globalThis || {};
const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  chart,
  chartOfTheYear,
  editorChoice,
  ui,
  settings,
});

export const makeStore = () =>
  configureStore({
    reducer: ((state, action) => {
      if (action.type === 'HYDRATE') {
        return { ...state, ...action.payload };
      } else {
        return combinedReducer(state, action);
      }
    }) as typeof combinedReducer,
    preloadedState: _window[REDUX_STORAGE_KEY],
    middleware: (gDM) => gDM().concat(api.middleware),
  });

export const store = makeStore();
export const reducer = combinedReducer;

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = AppStore['dispatch'];
