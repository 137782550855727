import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'src/hooks/store';
import { useOnClickOutside } from 'src/hooks/click-outside';
import { selectChartsList, selectChartOfTheYear } from 'src/store/chart-of-the-year/selectors';
import { selectUI } from 'src/store/ui/selectors';
import { setIsFixedNavigation } from 'src/store/ui/index';
import { routes } from 'src/constants';
import { analytics } from 'src/utils';

import CategoriesNavigation from './CategoriesNavigation';
import CategoriesNavigationMobile from './CategoriesNavigationMobile';

import arrowDwon from 'src/assets/images/navigation/arrow-down.svg';

import css from './TopSection.module.scss';

const TopSection = () => {
  const [isOpenChartsSelect, setIsOpenChartsSelect] = React.useState<boolean>(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const dispatch = useAppDispatch();
  const chartsList = useAppSelector(selectChartsList);
  const chartsListForSort = chartsList ? [...chartsList] : [];

  const reversedChartsList =
    chartsList && chartsList.length > 1 ? chartsListForSort.sort((a, b) => b.year - a.year) : chartsList;

  const ui = useAppSelector(selectUI);
  const { year } = useParams();
  const chart = useAppSelector(selectChartOfTheYear);
  const navigate = useNavigate();
  const dropDownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, [ui.isFixedNavigation]);

  React.useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth <= 799);
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  React.useEffect(() => {
    const navigationWrapper = document.getElementsByClassName('navigationWrapper')[0];
    const navigationWrapperScroll = () => {
      if (navigationWrapper && isMobile) {
        if (isOpenChartsSelect) {
          setIsOpenChartsSelect(false);
        }
      }
    };
    if (navigationWrapper) {
      navigationWrapper.addEventListener('scroll', navigationWrapperScroll);
    }
    return () => {
      if (navigationWrapper) {
        navigationWrapper.removeEventListener('scroll', navigationWrapperScroll);
      }
    };
  }, [isOpenChartsSelect]);

  const scrollListener = () => {
    const gasket = document.getElementById('gasket');

    if (gasket) {
      const gasketOffsetTop = gasket.getBoundingClientRect().top;

      if (gasketOffsetTop < 0 && !ui.isFixedNavigation) {
        dispatch(setIsFixedNavigation(true));
      }

      if (gasketOffsetTop > 0 && ui.isFixedNavigation) {
        dispatch(setIsFixedNavigation(false));
      }
    }
  };

  const clickOnChartsSelect = () => {
    setIsOpenChartsSelect(!isOpenChartsSelect);
  };

  const clickOnChart = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const year = e.currentTarget.dataset.year;

    if (year) {
      analytics.gtag.event('Chart_Year', { CUSTOM_PARAMETER: `${year}_מצעד` });
      navigate(routes.ANNUAL_CHART.replace(':year', year));
      setIsOpenChartsSelect(false);
    }
  };

  const handleClickOutside = () => {
    setIsOpenChartsSelect(false);
  };

  useOnClickOutside<HTMLDivElement>(dropDownRef, handleClickOutside);

  return (
    <div className={css.topSectionWrapper}>
      <div className={css.intro}>
        <video
          src={isMobile ? chart.header?.mobileVideoBgUrl : chart.header?.desktopVideoBgUrl}
          autoPlay
          muted
          loop
          playsInline
        ></video>
        <div className={css.logoWrapper}>
          <img src={chart.header?.logoUrl} alt="logo" />
        </div>
      </div>
      <div id="gasket"></div>
      <div className={cn(css.topSectionNav, ui.isFixedNavigation && css.fixed)}>
        <div className={cn(css.navigationWrapper, 'navigationWrapper')}>
          <div className={cn(css.chartsSelect, 'chartsSelect')} ref={dropDownRef}>
            <div className={css.currentChart} onClick={clickOnChartsSelect}>
              <span>
                {!isMobile && 'מצעד'} {year}
              </span>
              <img src={arrowDwon} alt="" />
            </div>
            <div className={cn(css.chartsDropdown, isOpenChartsSelect && css.open)}>
              {reversedChartsList &&
                reversedChartsList.length &&
                reversedChartsList.map((chart) => {
                  return (
                    <div key={chart.chartId} data-year={chart.year} className={css.chart} onClick={clickOnChart}>
                      מצעד {chart.year}
                    </div>
                  );
                })}
            </div>
          </div>
          {isMobile ? <CategoriesNavigationMobile /> : <CategoriesNavigation />}
        </div>
        <div className={cn(css.chartsDropdownMobile, isOpenChartsSelect && css.open)}>
          {reversedChartsList &&
            reversedChartsList.length &&
            reversedChartsList.map((chart) => {
              return (
                <div key={chart.chartId} data-year={chart.year} className={css.chart} onClick={clickOnChart}>
                  {chart.year}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TopSection;
