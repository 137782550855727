import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useMatch } from 'react-router';

import { cdnUrl } from 'src/utils';
import { routes } from 'src/constants';
import { ChartType } from 'src/types/chart';
import { useAppSelector } from 'src/hooks/store';
import { BASE_CLIENT_URL } from 'src/constants/other';
import { selectSettings } from 'src/store/settings/selectors';

import faviconUrl from 'src/assets/favicon/favicon.png';

const Head = () => {
  const location = useLocation();
  const chart = (useMatch(routes.MAIN)?.params.chart as ChartType) || null;
  const settings = useAppSelector(selectSettings);
  const SEO = {
    title: settings?.seo?.[chart || ChartType.TOP100]?.title ?? 'Mako Charts 2023',
    description: settings?.seo?.[chart || ChartType.TOP100]?.description,
  };
  const OG = {
    title: settings?.og?.[chart || ChartType.TOP100]?.title ?? 'Mako Charts 2023',
    description: settings?.og?.[chart || ChartType.TOP100]?.description,
    image: cdnUrl(settings?.og?.[chart || ChartType.TOP100]?.image),
  };

  return (
    <Helmet>
      <title>{SEO.title}</title>
      <meta name="description" content={SEO.description} />
      <meta property="og:title" content={OG.title} />
      <meta property="og:description" content={OG.description} />
      <meta property="og:image" content={OG.image} />
      <link rel="icon" type="image/png" href={faviconUrl} />
      <link rel="canonical" href={`${BASE_CLIENT_URL}${location.pathname}`} />
    </Helmet>
  );
};

export default Head;
