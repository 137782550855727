import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IModel } from '@api/models/editor-choice/interface';
import * as actions from './actions';

interface EditorChoice {
  songs: IModel[];
}

const initialState: EditorChoice = {
  songs: [],
};

export const editorChoice = createSlice({
  name: 'editorChoice',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.songs = action.payload.songs.sort((a, b) => a.order - b.order);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actions.getEditorChoice.fulfilled, (state, action) => {
      state.songs = action.payload.sort((a, b) => a.order - b.order);
    });
    builder.addCase(actions.getEditorChoice.rejected, (state) => {
      state.songs = [];
    });
  },
});

export const { init } = editorChoice.actions;
export default editorChoice.reducer;
