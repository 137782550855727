import React from 'react';
import css from './FirstPlace.module.scss';

const FirstPlace = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={100}
      height={100}
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      className={css.firstPlace}
    >
      <defs>
        <clipPath id="clipPath1">
          <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
            1
          </text>
        </clipPath>
      </defs>
      <g clipPath="url(#clipPath1)">
        <rect x="0" y="0" width="100%" height="100%" fill="white" />
        <image
          style={{ overflow: 'visible' }}
          width="100"
          height="100"
          xlinkHref="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAA7jAAAVdwAAG9T/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAl4BPQMBIgACEQEDEQH/
xAC9AAADAQEBAQAAAAAAAAAAAAAAAQIDBAUGAQEBAQEBAQAAAAAAAAAAAAABAgADBAUQAQEBAAEC
AQkJAQEBAAAAAAARAQISAwUQIDAhEyMzNBVAUDEiMgQkJTUGFhQRAAECBQIFAgQFAwUAAAAAAAIA
A1ABkbFyBDQR0dIzc8ESIVET0zFhgTKyQUJSoWMUJBUSAAEDAgMHBAICAwAAAAAAAAEAAnJAsUGR
AxGBMoKSsnMxcRIzIUIg4TBQUf/aAAwDAQACEQMRAAAA+k+bPP8Av/P71wrvPecA13HAau84Fr7z
gWrvOBbp3nnh07zzwr0F54V6B55O9A88jegeeTPoHnkz6B55E+g/OJn0TzyZ9A88D0DzwPV+n+D6
Ob3cHocH2PGhroIZqQDSGtaGt0SYdEma0mTaTCkBKAQAESATIMiUwmACZAciGAtstTejwehwfT8i
GdBKlqQxqRrWhrWlSOiTDokwtJhaGRkBIDIlDIlDIhMJkAmQYCGAtstZ3o8Pfw/U8aGdCSllKhqV
QXKo3SVS3SSkXIyekjJpKidIyBDIlNkyhkShkyhkymEgDBa56m9Hh7+L6vjkpdJQzMlLVJS1yUi5
Vo6SqC5VE3KompGSpUQS2QIZEoZMoZEoZMobCWwFrnqPpcPfxfX8UlK5SozJS1SUipKWuVaLlUT0
lUTUqiakpQyURpKImSlIhuJkoiZbAQyRDAWueo+lxd/F9jxyUdIkpbSUFQUaoKC4KU3KtTcq1NSU
pqSlClRGkogkogkZEobmZKJJKJEMMtI1z6XH28f2vIhl85KNpVAyrWqVam5VqblUTcqialURUq1D
JSgkojSURMlEElEklEzJROlsBaRrq9Lj7eT7nlkornIzaSjMFAwUTcqibgomoKIuCiGCiGVRGkog
gogkoiZKJJKJJbJEMMtI11ejydvJ93hJRXOSlhKgZKQyUipKUXJSi5KU1JRzYKIYKIJVEElEEjJl
DIEMkRQaSg06Tpq9Hk7OX73OSiuUlIUM2kpSpUTUqiblWoqVaipKUMlEaCjnoKImVRMyURMjJEUQ
SUGlsMtI0b9Dl6+b75JSeaGGQwZKBlUTUlKKkoipVEVKtQyURoKOZBRMwURMqiIkpTKKJJbDIbGd
Jtvv5urm+/aGbmlRtJRLIwUMmpKIqRkMlEVKohlWoJKI0FETBREQUTEFEzLZIigZKDTpOjfbzdXN
9/qhmhDDIYKGTUlEslKKQyFKiKkojSUo0lEaCiJgtREq1MyrCJKJJKDSUGnSbq+vn6ef73dDNCGG
QyVDJpDJUMikMhQyKQyFKiNJRBKtQSrJmCiYgtEyUBJQElBptXVdPN08/wB3uhhKGGTAQHNIZKhk
UhuFDIpDfNkohkogkpSSUSQWpiSgmCgmSjElATaq625+nn+52QyRDNgCUBzSYSgOKAcUhkUDOaii
GWyNJROkomZVBMlImSgmRmmSjE2qt05+jn+31AJAAQYIBKMIoGTQDigZzoBxQMhTZChkZKgmSkTJ
S0yUaJGaZKMTc3efP0c/2egBOADDTEAmmBKwIpg4oacWNPnQwhGEUDJyGAlSJSpaENMIZpQGFc1e
XP0c/wBmgCMxAsAWJyjTmhpxTE4ptEVQnFNpxQ04RhNAEiTGEBoQDCGaUBgqasjn6Ob69MTjMQLE
xYiWhE1QiWhOabRzqhOabRFU5cU3LlaFpYjSAtIAwCMAGkqasz59+f6qxELEC3LFuWLcuVuXNNy4
aETVOXFUS5acktCJzEYYjDEaWg0gjDEYdRXQy5ujm+nmIiqJYtyGoQNCJack1Tklpy5aJcVThzqJ
Y0SGpyBRJiiTFEmliMMRs6irnLm6OX6TQiKYgaJZm5BpyS05BpyS05JbJJbJDWSGpyGokCnJtRJi
hGGIwxGzvOqnLl6eT6NUSRVOQaJDU5BpyGpyS04Y05J1OQacOdRLNRIFOTaiXiiQ1EmKEYYjZ3nd
zjy9PJ77oki6JZqJBpwzUSxolmokNZJLZIanIanDCnDNRJinJtRLCiTaiTFCNned1OXH1cfu6US4
6USGol7USGpyGpwzU4Y04ZqJYUSGskNTkCnJtRLCiTaiXiiTaiTary0qcuPq4/b1okjpRIanJtRL
NRIayQ1khqcgU5DU4ZqJeKJZqJMWSGoRiiTahANybVeV3OfF18Xr7UST0olmok2pyGpwzUSzUSYs
kNZIayQKcmKchqcgU4e1EvDchqJezEYemWlTlw9nD6+9EuetEgUS9qJDU5NqcgU5DU5DU4eKcMKc
hqcmKchqJeKJZm5MUS9mI2emWlRlw9nD6/RRJHWiXhuQ1OHtRLCiWaiXiiWaiWFEsKcm1OQKEbUI
CiXhuTahBmI2emWlRlwd3B6/QxEdaJNqJe1EsKJZqJeKJZqJYUS8U5ApyzNy8Nywbk2oQFCNm5MU
SzPTLSox4O7g9fqYiOrcmKEbUSzUS8USwolhTk2pywolhQjFCDUIChGKEGbl4YjZiNnrjrUY+f3+
f6vSxE9qJYNybU5ApybU5YNywoRinLBuWahGKEBQgKEbUIBtGzEYbl7PTLSox8/v8/1epiJ6tyGo
RihBqEYpywbQFCezcsG0BQnhuWDcsG5ezEwYjZtGGIM9MtajHzvQ8/1eoAnqNGzE9mJg3Lw2gKcs
G0GoTw2gG0wYnhiYMTBiezEYYBho2emWtRj53o+d6vSAT2ANmADE9m5YNpgxPDaYMTBtANp4YAMA
zaMMAGJ7AGzEA9M9KiPM+4+P9lc5sc/XibG2L1NsnqYyeobM1YZPR4zNQM3owzNGGb0MZvRhk9GG
Zo9snoBm9DbM0MZmptl0V9N183//2gAIAQIAAQUA/bfte1x7Xse09j2nse09j2nse09j2nse09j2
m9ntN7Xab2u23tdtvb7be3wbw4N4cHRwdHBz7XHXZ+F6DWta1rW+Xl+HZ+F6DW63W63W63W6qt31
dn4XlqqrdbrdbrdbrdbrdVW67PwlVVVW63W63W63W63W6qt12t91VVVVut1ut1ut1ut1ut1Vbrtb
7uqqq3W63W63W63W8m8m8m8nU6ldrfd1VVW63W63k3k3k3k3k3k3k3k6nUrt7+Sqqt1ut5N5N5N5
N5N5N5N5N5N5Op1M5O3v5KqupvJvJvJvJvJvJvJvJvJvJvJvJ1OpnJw38vU6nU3k3k3k3k3k3k3k
3k3k3k3k3k3k6nUzl6+PL8vU6nU3k3k3k3k3k3k3k3k3k3k3k3k3k6nU48vXnL1dTqdTeTeTeTeT
eTeTeTeTeTeTeTeTqdTqcd9ecvV1Op1N5N5N5N5N5N5N5N5N5N5N5Op1OpXDfX1Op1OpvJvJvJvJ
vJvJvJvJvJvJ1Op1Krhvr3fX1OpvJvJvJvJvJvJvJvJvJvJvJ1Kqq4b6+W+vqVut5N1ut1vJvJvJ
vJuqqqqu3vr5b+aq3W63W63W63W63W6qqqq7e/m57+aq3W63W63W63W63VVfN7f6ue/nqq3W63W6
3W63W63fP7f6u5v56qt1ut1rWta3z+3+rufrqq1rWta1voO3+rufE8zWta1qIieb2/1dz4nma1rW
+SIiJ5nb/V3fiebrfLERERER2/1dz4noYiIiIiO3nr7nxPQRERERERwz19z4noIiIiIiI4Z6+5+v
0EREREREcPx7n6/PiIiIiIiOH49z9fnzyT0PD8e5+v7Fw/Hn+v7Fw/Hn+v7Fw/Hn+v7Fw/Hn+v7F
w/Hn+r7Fx/Hu8JziIiIiIiIiIiI7XbzX/9oACAEDAAEFAO73ee8858mc+TOXJnLWbrN1jPJ6vJrW
63dbuurXVrjz1z/XjNZrNZrNZrGeTda1rdbvlxz389ZrNZrNZrNZqq3W63W63W6qs319zfz1ms1m
s1ms1nJ1KrdbrdbrdVWb6+5vvKzWazWcmcmcmcnU6nU3k3W63VVXHfX3d95VZyZyZyZyZyZyZydT
qdTeTeTqdSq476+9vvarNZyZyZyZyZydTqdTqdTeTqVVcd9fe33tVms1nJms1nJ1Op1OpvJ1Op1K
rhvr7++9qqzWazWazVVVVVVVcN9ff+N5cYxmqqqrdVVVXDfX3/jeXGMZ5u63W6qqrt76+/nvoiMx
mMxmebrWtXy9v8e9nvYiMxmMxmIiNxuNxuNxER289fez3sR0ozi6XS6UdLcbxbxbxbxdKOlwz193
j7zpdLpZxdLOLOLpdLpdLpbxbxbxdLpdLhx9fc4/n6XS6XSzi6XS6XS6XQ6G8G8G8HQ6XS48fXz4
/n6XS6XSzizizizi6XQ6HQ3g3g3g3g6HQ48fXy4/m6XS6WcWcWcWcWcWcXS6HQ3g3g3g3g3g6GcW
8fX0ul0ulnFnFnFnFnF0ul0N4N4N4t4t4ul0ul0ul0ulnFnFnFnFnFnF0ulvFvFvFvFvF0ulnF0u
l0ulnF0s4s4s4s4ojcbjcbxdLpbxcePq6XS6XS6XSzizGYiI3EbjcdLpbjjx/L0ul0ul0oiIiIiI
iI5Z6uGfk6XS6XSiIiIiIiIiOWert5+TpdKIiIiIiIiIiOWertZ7uIiIiIiIiIiIjnnq7We7iIiI
iIiIiIiI556uznuoiIiIiIiIiIiOeers57qIiIiIiIiIiIjnnq7Ge5iIiIiIiIiIiI556uxnuYiI
iIiIiIiIiOeer9vnuYiIiIiIiIiIiO5+H7fPcRERERET0Pc/D9v8CIiJ6bufp/bfA+xdz9P7b4H2
Lufp/bfA+xdz9P7Hv9f7frx146sdWOrHVjqx1Y6sdWOrHVjqx1Y6sdWOrHVj9x3tzf/aAAgBAQAB
BQDx7/oPp/Ll494xy3674u+u+Lvrvi7674u+u+Lvrvi7674w+veMPr3jD694w+veMPr3jD6/4w+v
+Mvr/jL6/wCMvr/jL6/4y/8AQeMv/QeMv/QeMv8A0HjL/wBB4y/9B4y/9B4y/wDQeMvr/jL6/wCM
vr/jL6/4y+v+Mvr/AIy+v+Mvr/jL6/4y+v8AjL9v/wBN4x2O5/6f9r9I8e3d8X+5uHc5Z+x8c/1/
ubj8r45/r/c3H5Xxz/W+5uPyvjn+t9zcflfG/wDW8k+5OPyvjf8AreSIiIiIieWIiJ9k4/K+N/6s
TyTyRERERERETyRERPT8flfGv9WIiIiIiIiIiIiIiIiejnl4/K+Nf6kRERERERERERERERETzIiI
iJ5ePy3jOf2kRERERERERERERERERERERERERxz+N4xn9nEREREREREREREREREREREREREcc/je
MZ/ZxERERERERERERERERERERERERHHP43i+f2cRERERERERERERERERERERERERxz+N4vn9lERE
REREREREREREREREREREREcc/jeLZ/ZRERERERERERERERERERERERERHHP4/iuf2MRERERERERE
RERERERERERERERxz+P4rn9jEREREREREREREREREREREREREcc/j+KZ/YRERERERERERERERERE
RERERERHHP4/in+hEREREREREREREREREREREREREcc/j+J/PxERERERERERERERERERERERERHH
PceJ/PxPJEREREREREREREREREREREREcc9x4l8/ERPJERERERERERERERERERERERme48S+e8yI
iIiIiIiIiIiIiIiIiIiIiMz3HiPz3lieSIiIiIiIiIiIiIiIiIiIiIzPceI/PT0ERPJERERERERE
REREREREZnufEfnfNnmxERERERERERERERERERme58Q+d9HERERERERERERERERERme58Q+d9JPJ
ERERERERERERERERme58Q+d+wRERERERERERERERme58Q+d9JE8kRERERERERERERERme68Q+c9P
PLEREREREREREREZnuv3/wA59jiIiIiIiIiIiIjM91+/+c+yxERERERERETyZ8L9/wDOfap5InmT
zIz4X7/5z7mz4f7/AOc+5s+H4h859zZ8PxD537mz4fiHzv2iqq+jz4fiHzv2WqqqvpM+H4h879iq
qqqvps+H4j89fJfS1VVVVVVfMvoc+H4j89VXyXz6qqqqqqqqqqq+Sr5+fD8S+eq+ZVVVVVVVVVVV
VVVVVfJVXzc+F4l8/VVVVVVVVVVVVVVVVVVVVVVVVfJnwvE9/n1VVVVVVVVVVVVVVVVVVVVVVVVW
b7rxPf59VVVVVVVVVVVVVVVVVVVVVVVVVm+68U3+wqqqqqqqqqqqqqqqqqqqqqqqqqs33Xim/wBh
VVVVVVVVVVVVVVVVVVVVVVVVVZvuvFd/saqqqqqqqqqqqqqqqqqqqqqqqqrN9z4tv9jVVVVVVVVV
VVVVVVVVVVVVVVVVZvufFt/sqqqqqqqqqqqqqqqqqqqqqqqqqrN9z4vv9lVVVVVVVVVVVVVVVVVV
VVVVVVVZvufGN/s6qqqqqqqqqqqqqqqqqqqqqqqqrN9z4xv9nVVVVVVVVVVVVVVVVVVVVVVVVVZv
uPGd/tKqqqqqqqqqqqqqqqqqqqqqqqqrN9x41v8AaVVVVVVVVVVVVVVVVVVVVVVVVVWb7jxrf7Wq
qqqqqqqqqqqqqqqqqqqqqqqqzfceN7/bVVVVVVVVVVVVVVVVVVVVVVVVVXHfceOb/bVVVVVVVVVV
VVVVVVVVVVVVVfJVcfgeOb/bVVVVVVVVVVVVVVVVVVVVVV8tVXH5fx3f7eqqqqqqqqqqqqqqqqr6
GqquPy/ju/29VVVVVVVVVV9BV9Nx3+P49/sVVVVVVVVVV82/Y+Py/j3+xVVVVV8l+28fl/H/APYX
yXzKv27j8v4//s+bfuHj8v8A9B/s/c3H5f8A6D/Z+5uPy/8A0H+z9zcflv8AoP8Aa+5uPy3/AEH+
19zcflv+k7fPt+Nfc3Htcv8A4P8Aquz4P3s7n7bscOXse29j23se29j23se29j23se29j23se29j
23se29j23se29j23se29j23se29j23se29j23se29j23se29l23su29l23su29l23su29l23su29
l23su29l23su29l23Y/bftOfLo8C+i//2gAIAQICBj8AYfg1znNDiXDb6r62dIX1s6QvrZ0hfWzp
C+tnSF9bOkL62dIX1s6QvrZ0hcDOkLgZ0hcDOkLgbkFwtyXC3JcLclwtyXC3JAgAEOF1pwbaj3i6
04NtR7wtODbUe8JkG2pGQbakZBtqRkG2pGRFI2Io802Io9xsh7Cj3GyHtR7nWpOV1qQxdZGjMXWK
PvRmLu0o+5ozF/aU6RozB/aU6RozB/aU6RozB/aU6RozB/aU+RozB/aU+RvRmL+0p8jejMX9pT5G
9GYu7SnyN6Mxd2lPkb0Zi6xT5GjMXWKdI0Zi6ydI0fK6ydI0fK6ydI0fK6ydI0fK6ydI0fK6ydI0
fK6ydI0fK6yOwggn/v8AP+1/awzWGawzWGawzWGawzWGawzWGac5zmjYx2wfIbSdi//aAAgBAwIG
PwA/kgA7AAuJ2a4jmvU5r1K9T/j9V6lepRG3Ap0jR5p0jR7inSNHuKfI3o9xsnzdej3GyfN16Pld
Zak3Xo+V1lqeR16MxdZankdejMXWK1PI69GYu7StTyOvRmL+0rUm69GYu7SnzdejMXWKfN16PldZ
OkaPc6ydI0e42R9zR5o+/wDvB7Ue8Iewo94umxFHvF02Io97bpkRaj5m3TINtR8zbrTg21HzNutO
DbUYk260/G21GJNuFp+NtqMSb3BaXjZajEm9wWl42WoxJncFpeJlqMSZ3BaXiZajE2dwWl4mWoxN
ncFpeJlqMTZ3BaYczUY5rGt/LHbDsH4IOzFft0lft0lft0lft0lft0lft0lY9JWPSVj0lY9JWORW
ORWORWORWORWORWORWORTGMZqH5arPk74H4tb8ht/Oxf/9oACAEBAQY/AJabTDI9TOXEiL9oSn+H
w/rNTKeqPjP5cJSpKS3R1kt0dZLdHWS3blZLduVkt25WS3blZLduVkt25WS3blZLduVkt25WS3bl
Zclu3Ky5LduVlyW7crLkt25WXJbtysuS3blZclu3Ky5LduVlyW7crLkt25WXJbtysuS3blZclu3K
y5LduVlyW7crLkt25WXJbtysuS3blZclu3Ky5LduVlyW7crLkt25WXJbtysuSkc35ujL9wOSlOU5
fL5y/Rf+j7J+/wB30vo/7vD3cOP+PD48fVaqc58Z+/h8flKUpSg7rf8AbN1ouH5yF2XqtVnO0Hc8
jf8AFxarOdoO55G/4uLVZztB3PI3ZxarOdoO55G7OLVZztB3PI3ZxarOdoO55G7OLVZ+kHc8jdnF
qc/SDueRuzi1OfpB3Mws4tTn6QdzMLOLU5wdzMLOLU5wdzMLGtRnB3Mwsa1GcHczCxrUZwc8wsa1
GcHPMLGtRnBzzCxp/KDnmFjT+UHPMLGn8oOeYWNP5Qc8wsafyg55hY09lBzyGxp7KDnkNjT2UHPI
bEnsoOeQ2JPZQcshsSeyg5ZDYk9lByyGxJ7KDlkNiT2UHLIbEnsoOWQ2JPZQcshsSeyg5ZDYk9lB
yyGxJ7KDlkNiT2UHLIbEnsoOWQ2JPZQcshsSeyg5ZDYk9lByyGxJ7KDlkNiT2UHLIbEnsoOWQ2JP
5QcshsSfyg5ZDYk/lByyGxJ/KDlkNiT+UHLIbEtRnByyGxLUZwcshsS1GcHLIbEtRnBzyGxLU5wc
8hsS1OcHPIbGtTn6Qc8hsa1OfpBzzCxrU5+kHPMLGtVnO0HPMLGtVnO0HPMLGtVnO0HPMLGtVnO0
HPMLGtX5J2g55hY1q/JO0HPMLGtX5J2g55hY1q/JO0oOeYWNavyTtKDnmFjWr8k7Sg55hY1q/JO0
oOeYWNazyTtKDuZhY1rPJO0oO5mFjWs8k7Sg7mYWNaqRy4e4pEP5yKUpyg7j39v1Wwl+c/a5OdE2
Ot1H/G1chnNk5CR8R+RSCU/hxXANW05L/KQuy/k1Jbhujn21uG6OfbW4bo59tbhujn21uG6OfbW4
bo50LcN0c6FuG6OdC3DdHOhbhujnQu+3RzoXfbo50Lvt0c6F326OdC77dHOhd9uh9C77dD6F326H
0LvhQ+hd8KH0LvhQ+hd8KH0LvhQ+hd8KH0LvhQ+hd8KH0LvhQ+hd4KH0LvBQ+hd4KH0LvBQ+hd4K
H0LvBQ+hd4KH0LvBQ+hd4KH0KX19YDQcfjwBwi/SXs4f6r2fU/6PHh9X4/U+px/H9vH3fp+H5L//
2Q=="
        ></image>
      </g>
    </svg>
  );
};

export default FirstPlace;
