import React from 'react';

export const Outbrain = () => {
  React.useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 1023px)').matches;
    const wrapper = document.querySelector('.outbrain-wrap');

    if (wrapper) {
      const outbrainContainer = document.createElement('div');

      const script = document.createElement('script');
      script.src = 'https://widgets.outbrain.com/outbrain.js';
      script.async = true;

      outbrainContainer.classList.add('OUTBRAIN');
      outbrainContainer.setAttribute('data-src', 'https://story.mako.co.il/idf_warmaterials');
      outbrainContainer.setAttribute('data-widget-id', isMobile ? 'MB_33' : 'SF_5');

      wrapper.appendChild(outbrainContainer);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
  return null;
};

export default Outbrain;
