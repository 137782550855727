import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IModel } from '@api/models/chart-of-the-year/interface';
import { SongItem } from '@api/routes/chart-of-the-year/preview/params';

import * as actions from './actions';

export type IChartOfTheYear = {
  year: number;
  isLoading: boolean;
  publishDate?: string;
  title?: string;
  meta?: IModel['meta'];
  winners?: IModel['winners'];
  header?: IModel['header'];
  items?: SongItem[];
};

const initialState: IChartOfTheYear = {
  year: 1800,
  isLoading: false,
};

export const chartOfTheYear = createSlice({
  name: 'chartOfTheYear',
  initialState,
  reducers: {
    initChartOfTheYear: (state, action: PayloadAction<actions.Init>) => {
      state.publishDate = action.payload.publishDate;
      state.title = action.payload.title;
      state.items = action.payload.items;
      state.year = action.payload.year;
      state.meta = action.payload.meta;
      state.header = action.payload.header;
      state.winners = action.payload.winners;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.getChart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(actions.getChart.fulfilled, (state, action) => {
      state.publishDate = action.payload.publishDate;
      state.title = action.payload.title;
      state.items = action.payload.items;
      state.year = action.payload.year;
      state.meta = action.payload.meta;
      state.header = action.payload.header;
      state.winners = action.payload.winners;
      state.isLoading = false;
    });
    builder.addCase(actions.getChart.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { initChartOfTheYear } = chartOfTheYear.actions;
export default chartOfTheYear.reducer;
