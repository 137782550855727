import React from 'react';
import cn from 'classnames';
import { NavLink, useParams } from 'react-router-dom';

import { routes } from 'src/constants';

import { useAppSelector } from 'src/hooks/store';
import { selectChartOfTheYear } from 'src/store/chart-of-the-year/selectors';
import { analytics } from 'src/utils';

import css from './CategoriesNavigationMobile.module.scss';

const CategoriesNavigationMobile = () => {
  const chart = useAppSelector(selectChartOfTheYear);
  const { year } = useParams();

  const scrollToMenu = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const gasket = document.getElementById('gasket');
    const { name } = e.currentTarget.dataset;
    if (name) {
      analytics.gtag.event('Winners_category', { CUSTOM_PARAMETER: `${name}` });
    }
    if (gasket) {
      setTimeout(() => {
        const top = gasket.getBoundingClientRect().top;
        window.scrollTo(0, Math.abs(top + window.scrollY));
      }, 100);
    }
  };

  return (
    <div className={cn(css.mobileNavigation)}>
      <div className={cn(css.navigationLinks)}>
        <div className={css.navigationItem}>
          <NavLink
            to={routes.ANNUAL_CHART.replace(':year?', `${year}`)}
            end
            onClick={scrollToMenu}
            data-name="שיר השנה"
          >
            {({ isActive }) => {
              return <span className={isActive ? css.active : ''}>שיר השנה</span>;
            }}
          </NavLink>
        </div>
        {chart.winners &&
          chart.winners.map((winner) => {
            return (
              <div className={css.navigationItem} key={winner.name}>
                <NavLink
                  to={routes.ANNUAL_CHART_CATEGORY_WINNER.replace(':year?', `${year}`).replace(
                    ':categorySlug',
                    winner.slug,
                  )}
                  end
                  onClick={scrollToMenu}
                  data-name={winner.categoryName}
                >
                  {({ isActive }) => {
                    return <span className={isActive ? css.active : ''}>{winner.categoryName}</span>;
                  }}
                </NavLink>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CategoriesNavigationMobile;
