import React from 'react';
import { Routes, Route } from 'react-router-dom';

import 'src/styles/index.scss';

import { routes } from 'src/constants';
import Main from 'src/components/pages/Main';
import AnnualChart from 'src/components//pages/AnnualChart';
import { Songs } from 'src/components/pages/AnnualChart/Songs';
import { CategoryWinner } from 'src/components/pages/AnnualChart/CategoryWinner';

export const App = () => {
  return (
    <Routes>
      <Route index path={routes.ROOT} element={<Main />} />
      <Route path={routes.ANNUAL_CHART} element={<AnnualChart />}>
        <Route index path="" element={<Songs />} />
        <Route path={`:${routes.ANNUAL_CHART_CATEGORY_WINNER.split(':').pop()}`} element={<CategoryWinner />} />
      </Route>
      <Route path={routes.MAIN} element={<Main />} />
    </Routes>
  );
};
