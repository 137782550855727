import React from 'react';
import { useMatch } from 'react-router';
import cn from 'classnames';

import { useAppSelector } from 'src/hooks/store';
import { selectSettings } from 'src/store/settings/selectors';
import { routes } from 'src/constants';
import socialsImg from 'src/assets/images/header/socials.svg';
import mailImg from 'src/assets/images/header/mail.svg';
import whatsappImg from 'src/assets/images/header/whatsapp.svg';
import facebookImg from 'src/assets/images/header/facebook.svg';
import css from './Socials.module.scss';
import { ChartType } from 'src/types/chart';

type Props = {
  className?: string;
};

enum SHARE {
  FACEBOOK = 'fb',
  WHATSAPP = 'wa',
  MAIL = 'mail',
}

const Socials = (props: Props) => {
  const { className = '' } = props;
  const chart = (useMatch(routes.MAIN)?.params.chart as ChartType) || null;
  const settings = useAppSelector(selectSettings);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggleBtnClick = () => {
    setIsOpen(!isOpen);
  };

  const onSocialBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id as SHARE;
    const { title = '', description = '' } = settings.og?.[chart || ChartType.TOP100] ?? {};
    const DOMAIN =
      process.env.ENV === 'production'
        ? 'https://hitlist.mako.co.il'
        : `https://${process.env.PROJECT_ID}.firebaseapp.com`;

    switch (id) {
      case SHARE.FACEBOOK: {
        const FACEBOOK_URL = 'https://www.facebook.com/sharer.php?u=';

        window.open(`${FACEBOOK_URL}${DOMAIN}`, 'sharer', 'width=555,height=600');
        break;
      }

      case SHARE.WHATSAPP: {
        const text = `${title}\n${description}\n${DOMAIN}`;
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        if (!isMobile) {
          window.open(`https://web.whatsapp.com/send?text=${encodeURIComponent(`${text}`)}`, '_blank');
        } else {
          window.location.href = `whatsapp://send?text=${encodeURIComponent(`${text}`)}`;
        }
        break;
      }

      case SHARE.MAIL: {
        const lineBreak = '%0D%0A';

        window.location.href = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(
          description,
        )}${lineBreak}${lineBreak}${DOMAIN}`;
        break;
      }

      default:
        break;
    }
  };

  React.useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleOutsideClick = (e: MouseEvent) => {
      if (!container.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className={cn(css.socials, isOpen && css.open, className)} ref={containerRef}>
      <button className={css.toggleBtn} type="button" onClick={onToggleBtnClick}>
        <img src={socialsImg} />
      </button>
      <div className={css.btns}>
        <button data-id={SHARE.MAIL} type="button" onClick={onSocialBtnClick}>
          <img src={mailImg} alt="mail" />
        </button>
        <button data-id={SHARE.WHATSAPP} type="button" onClick={onSocialBtnClick}>
          <img src={whatsappImg} alt="whatsapp" />
        </button>
        <button data-id={SHARE.FACEBOOK} type="button" onClick={onSocialBtnClick}>
          <img src={facebookImg} alt="facebook" />
        </button>
      </div>
    </div>
  );
};

export default Socials;
