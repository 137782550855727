/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import format from 'date-fns/format';
import getWeek from 'date-fns/getWeek';
import lottie from 'lottie-web';
import cn from 'classnames';

import makoLogoImg from 'src/assets/images/header/mako-logo-2.png';
import logoSmallImg from 'src/assets/images/header/logo_small.png';
import cokeLogoImg from 'src/assets/images/header/coke-4.png';

import { routes } from 'src/constants';
import { ChartType } from 'src/types/chart';
import { useAppSelector, useAppDispatch } from 'src/hooks/store';
import { selectChart } from 'src/store/chart/selectors';
import { selectUI } from 'src/store/ui/selectors';
import { setHeaderPosition } from 'src/store/ui';
import { analytics, zeroLeadingNumber } from 'src/utils';
import Socials from './Socials';
import logoAnimationData from './hitlist-logo/animations/animation_1.json';
import css from './Header.module.scss';

type Props = {
  className?: string;
  chartTab: ChartType;
  onChartChange: (chart: ChartType) => unknown;
};

const Header = (props: Props) => {
  const { chartTab, className = '', onChartChange } = props;
  const logoRef = React.useRef<HTMLDivElement>(null);
  const chart = useAppSelector(selectChart);
  const ui = useAppSelector(selectUI);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const subHeaderRef = React.useRef<HTMLDivElement>(null);
  const header = `מצעד המוזיקה הרשמי של ישראל`;

  const subHeader = `כמו בעולם, גם לישראל יש מ-2023 מצעד שמשקלל את יוטיוב, ספוטיפיי,<br />
אפל מיוזיק ושעות השיא של תחנות המוזיקה ברדיו. בלי הצבעות ובלי מניפולציות.<br />
<strong>mako</strong> גאה להציג את המצעד הרשמי של ישראל`;

  const subHeaderMobile = `כמו בעולם, גם לישראל יש מ-2023 מצעד שמשקלל את יוטיוב, ספוטיפיי, אפל מיוזיק ושעות השיא של תחנות המוזיקה ברדיו. בלי הצבעות ובלי מניפולציות.<br>
  mako גאה להציג את המצעד הרשמי של ישראל`;

  const sponsoredBy = 'בחסות';
  const week = 'שבוע';
  const top100Chart = 'Top 100';
  const israelChart = 'ישראלי';
  const internationslChart = 'בינלאומי';
  const newSongsChart = 'חדשים';
  const chartsAltText = 'לוגו משני מצעד המוזיקה השבועי';
  const shouldRenderSponsor = false;
  const chartDate = chart.publishDate ? new Date(chart.publishDate) : null;

  const onTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const chart = e.currentTarget.dataset.chart as ChartType;
    const map = {
      [ChartType.NEW_SONGS]: {
        event: 'New_Chart',
        route: generatePath(routes.MAIN, { chart: ChartType.NEW_SONGS }),
      },
      [ChartType.INTERNATIONAL]: {
        event: 'International_Chart',
        route: generatePath(routes.MAIN, { chart: ChartType.INTERNATIONAL }),
      },
      [ChartType.ISRAEL]: { event: 'Israeli_Chart', route: generatePath(routes.MAIN, { chart: ChartType.ISRAEL }) },
      [ChartType.TOP100]: { event: 'Top_100_Chart', route: routes.ROOT },
    };

    analytics.gtag.event(map[chart].event);
    onChartChange(chart);
    navigate(map[chart].route + window.location.search);
  };

  React.useEffect(() => {
    const subHeader = subHeaderRef.current;
    const logo = logoRef.current;

    if (logo) {
      lottie.loadAnimation({
        container: logo,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: logoAnimationData,
      });

      const title = document.createElement('title');
      title.innerText = `לוגו ראשי מצעד המוזיקה השבועי `;
      logo.querySelector('svg')?.prepend(title);
    }

    if (subHeader) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          const isFixed = entry.intersectionRect.top === 0 && entry.intersectionRatio < 1 && window.scrollY > 200;

          dispatch(setHeaderPosition(isFixed ? 'fixed' : 'normal'));
        },
        {
          threshold: [1],
        },
      );

      observer.observe(subHeader);

      return () => {
        observer.disconnect();
      };
    }

    return () => {
      lottie.destroy();
      if (logo) logo.innerHTML = '';
    };
  }, []);

  const renderChartDate = () => {
    if (!chartDate) {
      return null;
    }

    const title = chart.title;
    const dateRegex = /^\d{1,2}\.\d{1,2}\s?-\s?\d{1,2}\.\d{1,2}\.\d{4}$/;
    const isTitleValid = title && dateRegex.test(title);
    let toDate = chartDate;
    let fromDate = new Date(chartDate.getTime() - 7 * 24 * 60 * 60 * 1000);

    if (isTitleValid) {
      const [fromDateStr, fromMonthStr] = title.split('-')[0].trim().split('.');
      const [toDateStr, toMonthStr, toYearStr] = title.split('-')[1].trim().split('.');

      toDate = new Date(+toYearStr, +toMonthStr - 1, +toDateStr);
      const fromYear = new Date(toDate.getTime() - 7 * 24 * 60 * 60 * 1000).getFullYear();

      fromDate = new Date(+fromYear, +fromMonthStr - 1, +fromDateStr);
    }

    const creationDate = fromDate < toDate ? fromDate : toDate;

    return (
      <div className={css.chartDate}>
        <div className={css.date}>
          {isTitleValid ? (
            <span>
              {/* @ts-ignore-next-line */}
              <time dateTime={format(fromDate, 'yyyy-MM-dd')} content={format(fromDate, 'dd.MM.yyyy')}>
                {format(fromDate, 'dd.MM')}
              </time>
              {` - `}
              {/* @ts-ignore-next-line */}
              <time dateTime={format(toDate, 'yyyy-MM-dd')} content={format(toDate, 'dd.MM.yyyy')}>
                {format(toDate, 'dd.MM.yyyy')}
              </time>
            </span>
          ) : (
            <span>{title}</span>
          )}
        </div>
        <div className={css.week}>
          <span>
            {week}
            {` `}
            {zeroLeadingNumber(getWeek(chart.createdDate ? new Date(chart.createdDate) : creationDate))}
          </span>
        </div>
      </div>
    );
  };

  const renderNavBar = () => {
    return (
      <div className={css.navBar}>
        <div className={css.contentWrap}>
          {renderChartDate()}
          <div className={css.tabs}>
            <button
              data-chart={ChartType.TOP100}
              className={cn(css.mainChart, chartTab === ChartType.TOP100 && css.active)}
              type="button"
              onClick={onTabClick}
            >
              <span>{top100Chart}</span>
            </button>
            <button
              data-chart={ChartType.ISRAEL}
              className={cn(css.localChart, chartTab === ChartType.ISRAEL && css.active)}
              type="button"
              onClick={onTabClick}
            >
              <span>{israelChart}</span>
            </button>
            <button
              data-chart={ChartType.INTERNATIONAL}
              className={cn(css.globalChart, chartTab === ChartType.INTERNATIONAL && css.active)}
              type="button"
              onClick={onTabClick}
            >
              <span>{internationslChart}</span>
            </button>
            <button
              data-chart={ChartType.NEW_SONGS}
              className={cn(css.newSongsChart, chartTab === ChartType.NEW_SONGS && css.active)}
              type="button"
              onClick={onTabClick}
            >
              <span>{newSongsChart}</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={cn(css.header, ui.headerPosition === 'fixed' && css.fixed, className)}>
      <div className={css.hero}>
        <Socials className={css.socials} />
        <div className={css.logoWrap}>
          <div className={css.logo} ref={logoRef}></div>
          <h1>{header}</h1>
          <h2 className={css.desktop} dangerouslySetInnerHTML={{ __html: subHeader }} />
          <h2 className={css.mobile} dangerouslySetInnerHTML={{ __html: subHeaderMobile }} />
          {shouldRenderSponsor && (
            <div className={css.sponsor}>
              <p>{sponsoredBy}</p>
              <a
                className={css.sponsorLogoLink}
                href="https://cocacola.onelink.me/VHZw/jp5lszqb"
                onClick={() => analytics.gtag.event('Click On Coca Cola Icon')}
                target="_blank"
                rel="nofollow"
              >
                <img src={cokeLogoImg} alt="Coke" />
              </a>
            </div>
          )}
        </div>
      </div>
      <div className={css.subHeader}>
        <div className={css.subHeaderNormal} ref={subHeaderRef}>
          {renderNavBar()}
        </div>
        <div className={css.subHeaderFixed}>
          <div className={css.contentWrap}>
            <div className={css.logos}>
              <a className={css.makoLogoLink} href="https://mako.co.il">
                <img className={css.makoLogo} src={makoLogoImg} alt="" />
              </a>
              {/* <a
                className={css.cokeLogoLink}
                href="https://cocacola.onelink.me/VHZw/jp5lszqb"
                onClick={() => analytics.gtag.event('Click On Coca Cola Icon')}
                target="_blank"
                rel="nofollow"
              >
                <img className={css.cokeLogo} src={cokeLogoImg} alt="" />
              </a> */}
            </div>
            <img className={css.logo} src={logoSmallImg} alt={chartsAltText} />
            <Socials className={css.socials} />
            {/* <a
              className={cn(css.cokeLogoLink, css.cokeLogoLinkFixed)}
              href="https://cocacola.onelink.me/VHZw/jp5lszqb"
              onClick={() => analytics.gtag.event('Click On Coca Cola Icon')}
              target="_blank"
              rel="nofollow"
            >
              <img src={cokeLogoImg} alt="Coke" />
            </a> */}
          </div>
          {renderNavBar()}
          {renderChartDate()}
        </div>
      </div>
    </div>
  );
};

export function getHeaderText(chartType: ChartType) {
  switch (chartType) {
    case ChartType.ISRAEL:
      return 'ישראלי - המצעד האמיתי של ישראל';

    case ChartType.INTERNATIONAL:
      return 'בינלאומי - המצעד האמיתי של ישראל';

    default:
      return 'TOP100 - המצעד האמיתי של ישראל';
  }
}

export default Header;
