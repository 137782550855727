import React from 'react';
import cn from 'classnames';
import format from 'date-fns/format';

import { SongItem } from '@api/routes/chart-of-the-year/preview/params';
import { Platform } from '@api/constants/charts';
import { analytics } from 'src/utils';
import { MONTHS } from 'src/constants/other';
import { MonthsType } from 'src/types/month';

import appleMusicImg from 'src/assets/images/content/apple-music.png';
import spotifyImg from 'src/assets/images/content/spotify.png';
import youtubeImg from 'src/assets/images/content/youtube.png';

import css from './Item.module.scss';

enum Actions {
  LYRICS = 'lyrics',
}

interface Props {
  data: SongItem;
  index: number;
  isActive?: boolean;
  onClick: (val: number) => void;
}

const Item = (props: Props) => {
  const songLyrics = 'מילות השיר';
  const { data, index, onClick, isActive } = props;
  const spotifyUrl = getIdentifiers(Platform.SPOTIFY, data)?.url.replace('/track', '/embed/track');
  const artist = data.altArtist || data.artist;
  const title = data.altTitle || data.title;

  const clickOnOpenBtn = () => {
    onClick(index);
    analytics.gtag.event('Open_Song_Details', { CUSTOM_PARAMETER: `${title} - ${artist}` });
  };

  const onPlatformBtnClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const platformCode = e.currentTarget.dataset.id as Platform;
    const url = getIdentifiers(platformCode, data)?.url;

    switch (platformCode) {
      case Platform.APPLE_MUSIC: {
        analytics.gtag.event('Listening_on_Apple_Music', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        break;
      }

      case Platform.SPOTIFY: {
        analytics.gtag.event('Listening_on_Spotify', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        break;
      }

      case Platform.YOUTUBE: {
        analytics.gtag.event('Listening_on_YouTube', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        break;
      }

      default:
        break;
    }

    if (url) {
      window.open(url, '_blank');
    }
  };

  const onActionBtnClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id as Actions;

    switch (id) {
      case Actions.LYRICS: {
        analytics.gtag.event('Song_Lyrics', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        window.open(data.shironetUrl, '_blank');
        break;
      }

      default:
        break;
    }
  };

  return (
    <div className={cn(css.item, cssClass(data.place))}>
      <div className={css.mainContent}>
        <div className={css.generalInfo}>
          <div className={css.image}>
            <img src={data.details.imageUrl} alt="" />
          </div>
          <div className={css.info}>
            <div className={css.infoWrapper}>
              <div className={css.songName}>{data.altTitle || data.title}</div>
              <div className={css.artistName}>{data.altArtist || data.artist}</div>
              <div className={css.releaseDate}>
                תאריך הוצאת השיר: {convertReleaseDate(data.releaseDate || data.details.releaseDate)}
              </div>
            </div>
            <button
              type="button"
              className={cn(css.openBtn, !spotifyUrl && css.disabled, spotifyUrl && isActive && css.active)}
              onClick={clickOnOpenBtn}
            >
              <span>האזינו לשיר</span>
              <span className={css.cross}></span>
            </button>
            {data.place === 1 ? (
              <div className={css.animatedFirstPlace}></div>
            ) : (
              <div className={css.fistPlacesNumber}>{data.place}</div>
            )}
          </div>
        </div>

        {data.place === 1 ? (
          <div className={css.animatedFirstPlace}></div>
        ) : (
          <div className={css.number}>{data.place}</div>
        )}
        <button
          type="button"
          className={cn(css.openBtnRegularMobile, !spotifyUrl && css.disabled, spotifyUrl && isActive && css.active)}
          onClick={clickOnOpenBtn}
        >
          <span>האזינו לשיר</span>
          <span className={css.cross}></span>
        </button>
      </div>
      {isActive && spotifyUrl && (
        <div className={css.player}>
          <div className={css.playerEmbed}>
            <iframe
              style={{ borderRadius: '12px' }}
              src={spotifyUrl}
              width="100%"
              height="100%"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
          <div className={css.playerFooter}>
            <div className={css.platforms}>
              {getIdentifiers(Platform.APPLE_MUSIC, data)?.url && (
                <button data-id={Platform.APPLE_MUSIC} type="button" onClick={onPlatformBtnClick}>
                  <img src={appleMusicImg} alt="Apple Music" />
                </button>
              )}
              {getIdentifiers(Platform.SPOTIFY, data)?.url && (
                <button data-id={Platform.SPOTIFY} type="button" onClick={onPlatformBtnClick}>
                  <img src={spotifyImg} alt="Spotify" />
                </button>
              )}
              {getIdentifiers(Platform.YOUTUBE, data)?.url && (
                <button
                  className={css.platformYT}
                  data-id={Platform.YOUTUBE}
                  type="button"
                  onClick={onPlatformBtnClick}
                >
                  <img src={youtubeImg} alt="YouTube" />
                </button>
              )}
            </div>
            <div className={css.actions}>
              {data.shironetUrl && (
                <button data-id={Actions.LYRICS} type="button" onClick={onActionBtnClick}>
                  {songLyrics}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function cssClass(place: number) {
  switch (place) {
    case 1:
      return css.first;
    case 2:
      return css.second;
    case 3:
      return css.third;
    case 4:
      return css.fourth;
    case 5:
      return css.fifth;
    case 6:
      return css.sixth;
    case 7:
      return css.seventh;
    case 8:
      return css.eighth;
    case 9:
      return css.ninth;
    case 10:
      return css.tenth;
    default:
      return css.regular;
  }
}

function convertReleaseDate(date: string) {
  return `${MONTHS[format(new Date(date), 'MMMM') as keyof MonthsType]} ${format(new Date(date), 'yyyy')}`;
}

function getIdentifiers(platformCode: Platform, data: SongItem) {
  if (platformCode === Platform.SPOTIFY && data.spotifyUrl) return { url: data.spotifyUrl };
  if (platformCode === Platform.APPLE_MUSIC && data.appleMusicUrl) return { url: data.appleMusicUrl };
  if (platformCode === Platform.YOUTUBE && data.youtubeUrl) return { url: data.youtubeUrl };
  return data.identifiers.find((item) => item.platformCode === platformCode);
}

export default Item;
