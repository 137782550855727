import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Result as ISettings } from '@api/routes/settings/get/params';
import * as actions from './actions';

const initialState: Omit<ISettings, 'calculation'> = {};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.og = action.payload.og;
      state.pixels = action.payload.pixels;
      state.seo = action.payload.seo;
      state.publication = action.payload.publication;
      state.chartOfTheYearPublication = action.payload.chartOfTheYearPublication;
    },
  },
});

export const { init } = settings.actions;
export default settings.reducer;
