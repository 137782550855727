import React from 'react';
import cn from 'classnames';

import { share } from 'src/utils/share';

import shareSrc from 'src/assets/images/header/socials.svg';
import mailSrc from 'src/assets/images/header/mail.svg';
import fbSrc from 'src/assets/images/header/facebook.svg';
import waSrc from 'src/assets/images/header/whatsapp.svg';

import css from './Shares.module.scss';

const Shares = () => {
  const [isOpenMenu, setIsOpenMenu] = React.useState<boolean>(false);
  const clickOnShareMenuBtn = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const clickOnSocialBtn = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const type = e.currentTarget.dataset.id;

    if (type === 'fb' || type === 'mail' || type === 'wa') {
      share({ type });
    }
  };

  return (
    <div className={css.sharesWrapper}>
      <div className={css.shareMenuBtn} onClick={clickOnShareMenuBtn}>
        <img src={shareSrc} alt="" />
      </div>
      <div className={cn(css.shares, isOpenMenu && css.open)}>
        <div className={css.social} data-id="mail" onClick={clickOnSocialBtn}>
          <img src={mailSrc} alt="" />
        </div>
        <div className={css.social} data-id="wa" onClick={clickOnSocialBtn}>
          <img src={waSrc} alt="" />
        </div>
        <div className={css.social} data-id="fb" onClick={clickOnSocialBtn}>
          <img src={fbSrc} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Shares;
