import { MonthsType } from 'src/types/month';

export const REDUX_STORAGE_KEY = '__REDUX_STORAGE';
export const CDN_URL =
  process.env.ENV === 'production'
    ? 'https://makohitlist.cdn-il.com'
    : `https://storage.googleapis.com/${process.env.PROJECT_ID}.appspot.com`;

const DOMAIN_MAP = {
  local: 'http://localhost:3100',
  development: `https://${process.env.PROJECT_ID}.firebaseapp.com`,
  production: 'https://hitlist.mako.co.il',
} as const;
export const BASE_CLIENT_URL = DOMAIN_MAP[process.env.ENV];

export const MONTHS: MonthsType = {
  January: 'ינואר',
  February: 'פברואר',
  March: 'מרץ',
  April: 'אפריל',
  May: 'מאי',
  June: 'יוני',
  July: 'יולי',
  August: 'אוגוסט',
  September: 'ספטמבר',
  October: 'אוקטובר',
  November: 'נובמבר',
  December: 'דצמבר',
};
