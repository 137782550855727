export const renderItems = (): { items: number; isShowMenu: 'hidden' | 'show' } => {
  const navigationWrapperWidth = document.getElementsByClassName('navigationWrapper')[0].getBoundingClientRect().width;
  const chartsSelectWidth = document.getElementsByClassName('chartsSelect')[0].getBoundingClientRect().width;
  // Here 40*2 - paddings for navigation wrapper from left/right, 80 - margin left for charts select, total 160
  const additionalWidth = 160;
  const categoriesDropdownWidth = 74;
  const fakeNavigationWidth = document.getElementsByClassName('fake-navigation')[0].getBoundingClientRect().width;
  const fakeNavigationItems = document.getElementsByClassName('fake-navigation-item');

  let items = 0;
  let isShowMenu: 'hidden' | 'show' = 'hidden';

  if (navigationWrapperWidth - additionalWidth - chartsSelectWidth > fakeNavigationWidth) {
    items = fakeNavigationItems.length;
    isShowMenu = 'hidden';
  } else {
    const fakeItemsLength = fakeNavigationItems.length;

    let itemsWidth = 0;
    const freeWidthForItems = navigationWrapperWidth - chartsSelectWidth - additionalWidth - categoriesDropdownWidth;

    for (let index = 0; index < fakeItemsLength; index++) {
      const itemWidth = fakeNavigationItems[index].getBoundingClientRect().width;

      itemsWidth = itemsWidth + itemWidth;

      if (itemsWidth < freeWidthForItems) {
        items = items + 1;
      } else {
        break;
      }
    }

    isShowMenu = 'show';
  }

  return {
    items: items,
    isShowMenu: isShowMenu,
  };
};
