import React from 'react';
import cn from 'classnames';

import { useAppSelector } from 'src/hooks/store';
import { selectChartOfTheYear } from 'src/store/chart-of-the-year/selectors';

import Item from './Item';
import InnerBanner from 'src/components/pages/Main/Content/InnerBanner';
import Footer from '../Footer';

import css from './Songs.module.scss';

interface Props {
  className?: string;
}

export const Songs: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [activeIndex, setActiveIndex] = React.useState<number>(-1);
  const chart = useAppSelector(selectChartOfTheYear);
  const isFirstPlaceAvailable = chart.items?.find((item) => item.place === 1);

  const onItemClick = React.useCallback(
    (index: number) => {
      if (index !== activeIndex) {
        setActiveIndex(index);
      } else {
        setActiveIndex(-1);
      }
    },
    [activeIndex],
  );

  const renderLiveEmbed = () => {
    return (
      <div className={css.embedWrapper}>
        <div className={css.embed}>
          <iframe src={chart.header?.embedLive} frameBorder="0" allowFullScreen></iframe>
        </div>
      </div>
    );
  };

  const renderRecordEmbed = () => {
    return (
      <div className={css.embedWrapper}>
        <div className={css.embed}>
          <iframe src={chart.header?.embedRecord} frameBorder="0" allowFullScreen></iframe>
        </div>
      </div>
    );
  };

  return (
    <div className={cn(css.songs, className)}>
      {!isFirstPlaceAvailable && chart.header?.embedLive && renderLiveEmbed()}
      {chart.items?.map((item, index) => {
        if (index === 9)
          return (
            <div className={cn(css.itemWrapper, css.withBanner)} key={item.uuid}>
              <Item data={item} index={index} isActive={index === activeIndex} onClick={onItemClick} />
              <div className={css.bannerWrapper}>
                <InnerBanner index="10" className={css.annualContentBanner} />
              </div>
            </div>
          );
        if (index === 19)
          return (
            <div className={cn(css.itemWrapper, css.withBanner)} key={item.uuid}>
              <Item data={item} index={index} isActive={index === activeIndex} onClick={onItemClick} />
              <div className={css.bannerWrapper}>
                <InnerBanner index="20" className={css.annualContentBanner} />
              </div>
            </div>
          );
        return (
          <div className={css.itemWrapper} key={item.uuid}>
            <Item data={item} index={index} isActive={index === activeIndex} onClick={onItemClick} />
            {item.place === 1 && chart.header?.embedRecord && renderRecordEmbed()}
          </div>
        );
      })}
      <Footer />
    </div>
  );
};
