import React from 'react';
import cn from 'classnames';
import makoLogo from 'src/assets/images/content/mako-logo.svg';

import css from './Footer.module.scss';

interface Props {
  isOnCategory?: boolean;
}

const Footer = (props: Props) => {
  const { isOnCategory = false } = props;

  const footerText =
    'מצעד המוזיקה השבועי מה זה היטליסט? מעולם לא קם בישראל גוף שימדוד באמת את "עוצמת השירים" בארץ ויכלול את כל הפלטפורמות הקיימות: מהרדיו, דרך יוטיוב, ספוטיפיי, אפל מיוזיק ורשתות חברתיות כמו טיקטוק. בשנות ה-70 וה-80 השירים דורגו על ידי המאזינים במכתבים וגלויות, בשנות ה-90 השתדרגו לטלמסר ומשנות ה-2000 מצביעים באינטרנט. זאת, בזמן שבכל העולם מצעדי המוזיקה תמיד התבססו על מכירות סינגלים ובשנים האחרונות על סטרימינג. כלומר, מעולם לא היה בישראל מצעד שחף מכל מניפולציה של המצביעים או העורכים. עד היום! mako גאה להיות גוף התקשורת הראשון שמציג את הלהיטים החמים של ישראל באמצעות פיתוח אלגוריתם ייחודי שמשקלל את הטעם של מאות אלפי ישראלים שמאזינים לשירים יום-יום בשלל פלטפורמות.';

  return (
    <div className={cn(css.footer, isOnCategory && css.onCategory)}>
      <div className={css.footerWrapper}>
        <div className={css.logo}>
          <img src={makoLogo} alt="makp-logo" />
        </div>
        <div className={css.text}>{footerText}</div>
      </div>
    </div>
  );
};

export default Footer;
