import React from 'react';
import cn from 'classnames';

import appleMusicImg from 'src/assets/images/content/apple-music.png';
import spotifyImg from 'src/assets/images/content/spotify.png';
import youtubeImg from 'src/assets/images/content/youtube.png';

import { DEFAULT_HIGHEST_POSITION } from '@api/constants/other';
import { Platform } from '@api/constants/charts';
import { ChartType } from 'src/types/chart';
import { ChartItem } from 'src/store/chart';
import { analytics, cdnUrl } from 'src/utils';
import FirstPlace from './FirstPlace';
import css from './Item.module.scss';

type Props = {
  className?: string;
  data: ChartItem;
  chartType: ChartType;
  index: number;
  isActive?: boolean;
  isPreview?: boolean;
  isChartPublished?: boolean;
  containerRef?: React.Ref<HTMLDivElement>;
  onItemClick: (index: number) => unknown;
};

type PositionProgress = 'up' | 'down' | 'none' | 'returnedToChart' | 'newItem';

enum Actions {
  LYRICS = 'lyrics',
}

const Item = (props: Props) => {
  const { data, index, isActive, isPreview, isChartPublished, chartType, className = '', onItemClick } = props;
  const prevWeek = 'שבוע שעבר';
  const allTimeRecord = 'מיקום שיא';
  const timesInChart = 'שבועות במצעד';
  const newLabel = 'חדש';
  const returnedToChartLabel = `חוזר`;
  const songLyrics = 'מילות השיר';
  const artist = data.altArtist || data.artist;
  const title = data.altTitle || data.title;
  const coverImg = data.imageUrl || data.details.imageUrl;
  const positionProgress = getPositionProgress(data, !!isPreview, !!isChartPublished);
  const spotifyUrl = data.sourceUrl || getIdentifiers(Platform.SPOTIFY, data)?.url.replace('/track', '/embed/track');

  const onClick = () => {
    if (!isActive) {
      analytics.gtag.event('Open_Song_Details', { CUSTOM_PARAMETER: `${title} - ${artist}` });
    }

    onItemClick(index);
  };

  const onPlatformBtnClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const platformCode = e.currentTarget.dataset.id as Platform;
    const url = getIdentifiers(platformCode, data)?.url;

    switch (platformCode) {
      case Platform.APPLE_MUSIC: {
        analytics.gtag.event('Listening_on_Apple_Music', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        break;
      }

      case Platform.SPOTIFY: {
        analytics.gtag.event('Listening_on_Spotify', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        break;
      }

      case Platform.YOUTUBE: {
        analytics.gtag.event('Listening_on_YouTube', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        break;
      }

      default:
        break;
    }

    if (url) {
      window.open(url, '_blank');
    }
  };

  const onActionBtnClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id as Actions;

    switch (id) {
      case Actions.LYRICS: {
        analytics.gtag.event('Song_Lyrics', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        window.open(data.shironetUrl, '_blank');
        break;
      }

      default:
        break;
    }
  };

  const renderPositionStats = () => {
    let highestPositionLabel =
      data.highestPosition === DEFAULT_HIGHEST_POSITION ? '-' : Math.min(data.position, data.highestPosition);

    if (positionProgress === 'newItem') {
      highestPositionLabel = data.position;
    }

    return (
      <div className={css.positionStats}>
        <div className={css.prevWeek}>
          <div className={css.positionStatsContent}>
            <p className={css.positionStatsTitle}>{prevWeek}</p>
            <p className={css.positionStatsValue}>{data.oldPosition === -1 ? '-' : data.oldPosition}</p>
          </div>
        </div>
        <div className={css.allTimeRecord}>
          <div className={css.positionStatsContent}>
            <p className={css.positionStatsTitle}>{allTimeRecord}</p>
            <p className={css.positionStatsValue}>{highestPositionLabel}</p>
          </div>
        </div>
        <div className={css.timesInChart}>
          <div className={css.positionStatsContent}>
            <p className={css.positionStatsTitle}>{timesInChart}</p>
            <p className={css.positionStatsValue}>{data.timesInChart + (isPreview && !isChartPublished ? 1 : 0)}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn(
        css.item,
        data.position === 1 && css.firstPlace,
        isActive && css.active,
        css[positionProgress],
        !spotifyUrl && css.disabled,
        className,
      )}
      onClick={onClick}
      ref={props.containerRef}
    >
      <div className={css.mainContent}>
        <div className={css.position}>
          <div className={css.number}>{data.position > 1 ? <span>{data.position}</span> : <FirstPlace />}</div>
          <div
            className={css.arrow}
            style={data.position === 1 && chartType !== 'main' ? { visibility: 'hidden' } : {}}
          >
            <span>{positionProgress === 'newItem' ? newLabel : returnedToChartLabel}</span>
          </div>
        </div>
        <div className={css.info}>
          <div className={css.cover}>
            {coverImg && <img src={cdnUrl(coverImg)} alt={artist} title={artist} loading="lazy" />}
          </div>
          <div className={css.texts}>
            <div className={css.artistAndTitle}>
              <h3 className={css.title}>{maxLengthText(title, 51)}</h3>
              <h4 className={css.artist}>{maxLengthText(artist, 57)}</h4>
            </div>
            {renderPositionStats()}
          </div>
          <div className={css.crossIconWrap}>
            <div className={css.crossIcon} />
          </div>
        </div>
      </div>
      {isActive && spotifyUrl && (
        <div className={css.player}>
          <div className={css.playerHeader}>{renderPositionStats()}</div>
          <div className={css.playerEmbed}>
            <iframe
              style={{ borderRadius: '12px' }}
              src={spotifyUrl}
              width="100%"
              height="100%"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
          <div className={css.playerFooter}>
            <div className={css.platforms}>
              {getIdentifiers(Platform.APPLE_MUSIC, data)?.url && (
                <button data-id={Platform.APPLE_MUSIC} type="button" onClick={onPlatformBtnClick}>
                  <img src={appleMusicImg} alt="Apple Music" />
                </button>
              )}
              {getIdentifiers(Platform.SPOTIFY, data)?.url && (
                <button data-id={Platform.SPOTIFY} type="button" onClick={onPlatformBtnClick}>
                  <img src={spotifyImg} alt="Spotify" />
                </button>
              )}
              {getIdentifiers(Platform.YOUTUBE, data)?.url && (
                <button
                  className={css.platformYT}
                  data-id={Platform.YOUTUBE}
                  type="button"
                  onClick={onPlatformBtnClick}
                >
                  <img src={youtubeImg} alt="YouTube" />
                </button>
              )}
            </div>
            <div className={css.actions}>
              {data.shironetUrl && (
                <button data-id={Actions.LYRICS} type="button" onClick={onActionBtnClick}>
                  {songLyrics}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getIdentifiers = (platformCode: Platform, data: ChartItem) => {
  if (platformCode === Platform.SPOTIFY && data.spotifyUrl) return { url: data.spotifyUrl };
  if (platformCode === Platform.APPLE_MUSIC && data.appleMusicUrl) return { url: data.appleMusicUrl };
  if (platformCode === Platform.YOUTUBE && data.youtubeUrl) return { url: data.youtubeUrl };
  return data.identifiers.find((item) => item.platformCode === platformCode);
};

const getPositionProgress = (data: ChartItem, isPreview: boolean, isChartPublished: boolean): PositionProgress => {
  if (data.oldPosition !== -1) {
    if (data.position < data.oldPosition) return 'up';
    if (data.position > data.oldPosition) return 'down';
    if (data.position === data.oldPosition) return 'none';
  }
  if (data.timesInChart <= (isPreview && !isChartPublished ? 0 : 1)) return 'newItem';
  return 'returnedToChart';
};

const maxLengthText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}…`;
};

export default Item;
