import React from 'react';
import cn from 'classnames';
import { NavLink, useParams } from 'react-router-dom';

import { routes } from 'src/constants';

import { useAppSelector } from 'src/hooks/store';
import { selectChartOfTheYear } from 'src/store/chart-of-the-year/selectors';
import { useOnClickOutside } from 'src/hooks/click-outside';
import { renderItems } from './helpers';
import { analytics } from 'src/utils';

import arrowDwon from 'src/assets/images/navigation/arrow-down.svg';
import css from './CategoriesNavigation.module.scss';

const CategoriesNavigation = () => {
  const [isOpenCategoriesSelect, setIsOpenCategoriesSelect] = React.useState<boolean>(false);
  const [itemsForRender, setItemsForRender] = React.useState(0);
  const [isShowCategoriesSelect, setIsShowCategoriesSelect] = React.useState<'hidden' | 'show'>('hidden');
  const chart = useAppSelector(selectChartOfTheYear);
  const { year } = useParams();
  const dropDownRef = React.useRef<HTMLDivElement>(null);

  const clickOnCategoriesSelect = () => {
    setIsOpenCategoriesSelect(!isOpenCategoriesSelect);
  };

  const scrollToMenu = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const gasket = document.getElementById('gasket');
    const { name } = e.currentTarget.dataset;
    if (name) {
      analytics.gtag.event('Winners_category', { CUSTOM_PARAMETER: `${name}` });
    }
    if (gasket) {
      setTimeout(() => {
        const top = gasket.getBoundingClientRect().top;
        window.scrollTo(0, Math.abs(top + window.scrollY));
      }, 100);
    }
  };

  const clickOnCategory = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    setIsOpenCategoriesSelect(false);
    scrollToMenu(e);
  };

  const handleClickOutside = () => {
    setIsOpenCategoriesSelect(false);
  };

  React.useEffect(() => {
    const { items, isShowMenu } = renderItems();
    setItemsForRender(items - 1);
    setIsShowCategoriesSelect(isShowMenu);

    window.addEventListener('resize', resizeNavigation);
    return () => window.removeEventListener('resize', resizeNavigation);
  }, []);

  const resizeNavigation = () => {
    const { items, isShowMenu } = renderItems();

    setItemsForRender(items - 1);
    setIsShowCategoriesSelect(isShowMenu);
  };

  useOnClickOutside<HTMLDivElement>(dropDownRef, handleClickOutside);

  return (
    <div className={cn(css.navigation, 'navigation', isShowCategoriesSelect === 'show' && css.withSelect)}>
      <div className={cn(css.navigationLinks, 'navigation-links')}>
        <div className={css.navigationItem}>
          <NavLink
            to={routes.ANNUAL_CHART.replace(':year?', `${year}`)}
            end
            onClick={scrollToMenu}
            data-name="שיר השנה"
          >
            {({ isActive }) => {
              return <span className={isActive ? css.active : ''}>שיר השנה</span>;
            }}
          </NavLink>
        </div>
        {chart.winners &&
          chart.winners.slice(0, itemsForRender).map((winner) => {
            return (
              <div className={css.navigationItem} key={winner.name}>
                <NavLink
                  to={routes.ANNUAL_CHART_CATEGORY_WINNER.replace(':year?', `${year}`).replace(
                    ':categorySlug',
                    winner.slug,
                  )}
                  end
                  onClick={scrollToMenu}
                  data-name={winner.categoryName}
                >
                  {({ isActive }) => {
                    return <span className={isActive ? css.active : ''}>{winner.categoryName}</span>;
                  }}
                </NavLink>
              </div>
            );
          })}
      </div>
      <div className={cn(css.fakeNavigation, 'fake-navigation')}>
        <div className={cn(css.navigationItem, 'fake-navigation-item')}>
          <a href="#">
            <span>שיר השנה</span>;
          </a>
        </div>
        {chart.winners &&
          chart.winners.map((winner) => {
            return (
              <div className={cn(css.navigationItem, 'fake-navigation-item')} key={winner.name}>
                <a href="/">
                  <span>{winner.categoryName}</span>
                </a>
              </div>
            );
          })}
      </div>

      {isShowCategoriesSelect === 'show' && (
        <div className={css.categoriesSelect} ref={dropDownRef}>
          <div className={css.categoriesSelectText} onClick={clickOnCategoriesSelect}>
            <span>עוד</span> <img src={arrowDwon} alt="" />
          </div>
          <div className={cn(css.categoriesSelectDropdown, isOpenCategoriesSelect && css.open)}>
            <div className={css.dropdownItem}>
              {chart.winners &&
                chart.winners.slice(itemsForRender, chart.winners.length).map((winner) => {
                  return (
                    <div className={css.dropdownItem} key={winner.name}>
                      <NavLink
                        to={routes.ANNUAL_CHART_CATEGORY_WINNER.replace(':year?', `${year}`).replace(
                          ':categorySlug',
                          winner.slug,
                        )}
                        end
                        onClick={clickOnCategory}
                      >
                        {({ isActive }) => {
                          return <span className={isActive ? css.active : ''}>{winner.categoryName}</span>;
                        }}
                      </NavLink>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoriesNavigation;
