import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Platform } from '@api/constants/charts';
import { IModel } from '@api/models/song/interface';
import * as actions from './actions';

export type ChartItem = IModel & {
  position: number;
  oldPosition: number;
  metric: number;
};

export type IChart = {
  platform: Platform.MAKO;
  publishDate?: string;
  createdDate?: string;
  title?: string;
  items?: ChartItem[];
  previous?: actions.Init;
  isOld?: boolean;
};

const initialState: IChart = {
  platform: Platform.MAKO,
};

export const chart = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.createdDate = action.payload.createdDate;
      state.publishDate = action.payload.publishDate;
      state.title = action.payload.title;
      state.items = action.payload.items.map((item, index) => {
        return { ...item, position: index + 1 };
      });
      state.previous = action.payload.previous;
      state.isOld = action.payload.isOld;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.getChart.fulfilled, (state, action) => {
      state.createdDate = action.payload.createdDate;
      state.publishDate = action.payload.publishDate;
      state.title = action.payload.title;
      state.items = action.payload.items.map((item, index) => {
        return { ...item, position: index + 1 };
      });
      state.previous = action.payload.previous;
      state.isOld = action.payload.isOld;
    });
  },
});

export const { init } = chart.actions;
export default chart.reducer;
