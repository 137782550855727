import { createAsyncThunk } from '@reduxjs/toolkit';

import { sendApiRequest } from '@api/utils/api';
import { PLATFORM_MAKO_PREVIEW_CHART } from '@api/constants/routes';
import { Result as PlatformPreviewResult } from '@api/routes/platforms/preview/params';
import { findPreviousPublishedChartId } from '@api/services/mako-charts/find-previous-published-chart-id';

import { CDN_URL } from 'src/constants/other';
import { RootState } from 'src/store';

import { init as initChart } from '../index';

export const getChart = createAsyncThunk<
  Parameters<typeof initChart>[0],
  { isPreviewMode: boolean; chartId?: string },
  { rejectValue: string }
>('chart/getChart', async (params, { rejectWithValue, getState }) => {
  try {
    const { settings } = getState() as RootState;
    const targetChartId = !params.chartId ? settings.publication?.chartId || '' : params.chartId;
    const chart = await getWeeklyChart(targetChartId, params.isPreviewMode);
    if (!chart) throw new Error(`Can not get chart by id: ${params.chartId}`);

    const previousChartId = findPreviousPublishedChartId(targetChartId, chart.publishDate, settings.publication);

    if (previousChartId) {
      const previousChart = await getWeeklyChart(previousChartId, params.isPreviewMode);

      if (previousChart) {
        chart.previous = previousChart;
      }
    }

    if (params.chartId === '65f943049ee0eac6390b6b84') {
      const songToFix = chart.items.find((s) => s.uuid === 'ca9ab388-7cdd-43dd-97be-d29961ae74f3');
      if (songToFix) songToFix.timesInChart = 2;
    }

    if (chart) {
      return chart;
    } else {
      throw new Error('getWeeklyChartError');
    }
  } catch (e) {
    console.info('error', e);
    return rejectWithValue((e as Error).message);
  }
});

export async function getWeeklyChart(chartId: string, isPreviewMode: boolean) {
  let chart: Parameters<typeof initChart>[0] | null = null;

  if (!isPreviewMode) {
    const chartUrl = `${CDN_URL}/published/${chartId}.json${
      process.env.ENV !== 'production' ? '?d=' + Date.now() : ''
    }`;
    const chartResponse = await fetch(chartUrl);
    chart = await chartResponse.json();
  } else {
    const response = await sendApiRequest<PlatformPreviewResult>({
      url: `${PLATFORM_MAKO_PREVIEW_CHART.replace(':chart', chartId)}?d=${Date.now()}`,
      method: 'GET',
      secure: true,
      scope: 'client',
    });

    chart = response.success ? response.data : null;
  }

  return chart;
}
