import React from 'react';
import { useLocation } from 'react-router';

import { isPreview } from 'src/utils';

export interface Params {
  containerId?: string;
  slotPath?: string;
  slotSize: [number, number] | [number, number][];
  targeting?: [string, string[]];
  closable?: boolean;
}

export function useDFP(params: Params) {
  const { containerId, slotPath, slotSize, targeting, closable } = params;
  const location = useLocation();
  const isPreviewMode = isPreview(location.search);
  const [isClosed, setIsClosed] = React.useState(process.env.ENV === 'local' || isPreviewMode);
  const [isRendered, setIsRendered] = React.useState(false);

  const minWidth =
    Array.isArray(slotSize[0]) === false
      ? slotSize[0]
      : (slotSize as number[][]).reduce((acc, [value]) => {
          return value < acc ? value : acc;
        }, (slotSize as number[][])[0][0]);
  const minHeight =
    Array.isArray(slotSize[0]) === false
      ? slotSize[1]
      : (slotSize as number[][]).reduce((acc, [, value]) => {
          return value < acc ? value : acc;
        }, (slotSize as number[][])[0][1]);

  React.useEffect(() => {
    if (isClosed || !containerId || !slotPath) {
      return;
    }

    const controller = new AbortController();
    const container = document.querySelector(`#${containerId}`);
    const googletag = (window as any).googletag;
    const topWindow: any = window.top;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-nextline
    let slot = null;

    if (googletag && container) {
      if (params.closable) {
        // When close clicked (mobile).
        // There is no clear way to handle close event on mobile. The only thing we have is 'onMaavaronClose()'
        // function invokation without any params. So we have to use global variable to handle it and pray that
        // we will not have more than one closable banner on the page.
        topWindow.DFP ??= {};
        topWindow.DFP.onMaavaronClose = function () {
          setIsClosed(true);
        };
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.addEventListener(
        'DFPDecision',
        (e: CustomEvent) => {
          const [, , isClosed] = e.detail;
          console.log('DFPDecision', e.detail);
          setIsClosed(isClosed);
        },
        { signal: controller.signal },
      );

      googletag.cmd.push(() => {
        slot = googletag
          .defineSlot(slotPath, slotSize, containerId)
          .addService(googletag.pubads())
          .setTargeting('MAKOPAGE', 'EX_hitlist');

        googletag.pubads().enableSingleRequest();

        if (targeting) {
          googletag.pubads().setTargeting(...targeting);
        }

        googletag.pubads().addEventListener('slotRenderEnded', (e: any) => {
          if (e.slot.getSlotElementId() === containerId) {
            setIsRendered(!e.isEmpty);
          }
        });
        googletag.enableServices();
      });

      googletag.cmd.push(() => {
        googletag.display(containerId);
      });
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-nextline
      googletag.destroySlots([slot]);
      controller.abort();
    };
  }, [isClosed, containerId, slotPath, slotSize]);

  return {
    isClosed,
    isRendered,
    minWidth,
    minHeight,
  };
}
