import React from 'react';
import ReactDOMClient, { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import { store } from 'src/store';
import { App } from 'src/App';

const container = document.getElementById('root');
const Wrap = (
  <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </HelmetProvider>
);

if (container) {
  if (process.env.SSR) {
    ReactDOMClient.hydrateRoot(container, Wrap);
  } else {
    createRoot(container).render(Wrap);
  }
} else {
  throw new Error('No #root element in DOM');
}
