import { buildCreateApi, coreModule, reactHooksModule, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import fetch from 'isomorphic-fetch';

import { API_HOST } from '@api/constants/routes';
import { SESSION as SESSION_COOKIE } from '@api/constants/cookies';

const customCreateApi = buildCreateApi(coreModule(), reactHooksModule({ unstable__sideEffectsInRender: true }));

export const api = customCreateApi({
  reducerPath: 'api',
  tagTypes: ['SETTINGS'],
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
    credentials: 'include',
    ...(typeof window !== 'undefined' && {
      fetchFn: fetch,
    }),
    prepareHeaders: (headers, { extra }) => {
      headers.set('Authorization', `Bearer ${(extra as any)?.cookies?.[SESSION_COOKIE]}`);

      return headers;
    },
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === 'HYDRATE') {
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
});

export default api;
