import { createAsyncThunk } from '@reduxjs/toolkit';

import { IModel as EditorChoiceModel } from '@api/models/editor-choice/interface';

import { CDN_URL } from 'src/constants/other';

export const getEditorChoice = createAsyncThunk<EditorChoiceModel[], void, { rejectValue: string }>(
  'editor-choice/getEditorChoice',
  async (params, { rejectWithValue }) => {
    try {
      let editorChoice: EditorChoiceModel[] = [];

      const editorChoiceUrl = `${CDN_URL}/editor_choice/editor-choice-songs.json${
        process.env.ENV !== 'production' ? '?d=' + Date.now() : ''
      }`;
      const editorChoiceResponse = await fetch(editorChoiceUrl);
      editorChoice = await editorChoiceResponse.json();

      return editorChoice;
    } catch (e) {
      console.error('Error while fetching editor choice', e);
      return rejectWithValue((e as Error).message);
    }
  },
);
