import React from 'react';
import cn from 'classnames';

import { useAppSelector } from 'src/hooks/store';
import { selectUI } from 'src/store/ui/selectors';
import { useDFP, type Params as DFPParams } from 'src/hooks/dfp';
import css from './SideBanner.module.scss';

interface Props {
  className?: string;
}

export function SideBanner(props: Props) {
  const { className = '' } = props;
  const [isFixed, setIsFixed] = React.useState<boolean>(false);
  const [params, setParams] = React.useState<DFPParams>({ slotPath: '', slotSize: [0, 0] });
  const ui = useAppSelector(selectUI);
  const dfp = useDFP(params);
  const title = 'פרסומת';

  React.useEffect(() => {
    const controller = new AbortController();
    const onResize = () => {
      const isDesktopMd = window.matchMedia('(min-width: 1424px)').matches;

      if (isDesktopMd && !params.containerId) {
        setParams({
          containerId: 'div-gpt-ad-1707743290287-0',
          slotPath: '/242748299/mako/hitlist/OZEN',
          slotSize: [[160, 600]],
        });
      }
    };

    onResize();
    window.addEventListener('resize', onResize, { signal: controller.signal });

    return () => controller.abort();
  }, [params.containerId]);

  return dfp.isClosed === false ? (
    <div className={cn(css.sideBanner, dfp.isRendered && css.rendered, ui.isFixedNavigation && css.fixed, className)}>
      <div className={css.bannerContainer}>
        <div className={css.title}>
          <p>{title}</p>
        </div>
        <div className={css.banner}>
          <div id={params.containerId} style={{ minWidth: `${dfp.minWidth}px`, minHeight: `${dfp.minHeight}px` }}></div>
        </div>
      </div>
    </div>
  ) : null;
}

export default SideBanner;
