import React from 'react';
import cn from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import { routes } from 'src/constants';

import { useAppSelector } from 'src/hooks/store';
import { selectCurrentYearChart } from 'src/store/chart-of-the-year/selectors';
import { analytics } from 'src/utils';

import Shares from 'src/components/common/Shares';

import makoLogo from 'src/assets/images/top-header/makoLogo.svg';
import hitListLogo from 'src/assets/images/top-header/hitlistLogo.svg';
import menuSrc from 'src/assets/images/header/menu.svg';
import closeSrc from 'src/assets/images/header/close.svg';

import css from './TopHeader.module.scss';

const TopHeader = () => {
  const [isOpenMenu, setIsOpenMenu] = React.useState<boolean>(false);
  const year = useAppSelector(selectCurrentYearChart);

  const clickMenuBtn = () => {
    setIsOpenMenu(true);
  };

  const clickCloseMenuBtn = () => {
    setIsOpenMenu(false);
  };

  const clickOnLink = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const { name } = e.currentTarget.dataset;
    analytics.gtag.event('Chart_Type', { CUSTOM_PARAMETER: `${name}` });
  };

  const renderMenuBtns = () => {
    if (isOpenMenu) {
      return (
        <div className={css.closeMenuBtn} onClick={clickCloseMenuBtn}>
          <img src={closeSrc} className={css.icon} alt="" />
        </div>
      );
    }
    return (
      <div className={css.menuBtn} onClick={clickMenuBtn}>
        <img src={menuSrc} className={css.icon} alt="" />
      </div>
    );
  };

  return (
    <header className={css.headerWrapper}>
      <Link to={routes.ROOT} className={css.hitlistLogo}>
        <img src={hitListLogo} alt="hitlist-logo" />
      </Link>
      <div className={css.menuBtns}>{renderMenuBtns()}</div>
      <nav className={cn(css.navgation, isOpenMenu && css.open)}>
        <NavLink to={routes.ROOT} className={css.navLink} data-name="המצעד השבועי" onClick={clickOnLink}>
          {({ isActive }) => {
            return <span className={isActive ? css.active : ''}>המצעד השבועי</span>;
          }}
        </NavLink>
        <NavLink
          to={routes.ANNUAL_CHART.replace(':year?', `${year}`)}
          className={css.navLink}
          data-name="המצעד השנתי"
          onClick={clickOnLink}
        >
          {({ isActive }) => {
            return <span className={isActive ? css.active : ''}>המצעד השנתי</span>;
          }}
        </NavLink>
      </nav>
      <Link to={routes.ANNUAL_CHART.replace(':year?', `${year}`)} className={css.makoLogo}>
        <img src={makoLogo} alt="mako-logo" />
      </Link>
      <Shares />
    </header>
  );
};

export default TopHeader;
