import React from 'react';
import { useLocation } from 'react-router';
import cn from 'classnames';

import { ChartType } from 'src/types/chart';
import { selectChart } from 'src/store/chart/selectors';
import { useAppSelector, useAppDispatch } from 'src/hooks/store';
import { selectUI } from 'src/store/ui/selectors';
import { setSaveChartMsgVisible } from 'src/store/ui';
import { selectEditorChoice } from 'src/store/editor-choice/selectors';
import { selectSettings } from 'src/store/settings/selectors';
import { analytics, isPreview } from 'src/utils';
import Item from './Item';
import RecomendedItem from './RecomendedItem';
import SideBanner from './SideBanner';
import InnerBanner from './InnerBanner';

import makoLogoImg from 'src/assets/images/header/mako-logo-footer.png';
import css from './Content.module.scss';

type Props = {
  className?: string;
  chartTab: ChartType;
};

const Content = (props: Props) => {
  const { chartTab, className = '' } = props;
  const [isMobile, setIsMobile] = React.useState(false);
  const [activeItemIndex, setActiveItemIndex] = React.useState(-1);
  const [activeRecomendedItemIndex, setActiveRecomendedItemIndex] = React.useState(-1);
  const [isAnyItemClicked, setIsAnyItemClicked] = React.useState(activeItemIndex > -1);
  const [tenthItemContainer, setTenthItemContainer] = React.useState<HTMLDivElement | null>(null);
  const [isOutbrainVisible, setIsOutbrainVisible] = React.useState(false);
  const tenthItemIntersectionObserverRef = React.useRef<IntersectionObserver | null>(null);
  const noNewSongsText = `אין כניסות חדשות למצעד השבוע`;
  const recomendedSongsText = `המלצת מערכת`;
  const chart = useAppSelector(selectChart);
  const recomendedItems = useAppSelector(selectEditorChoice);
  const ui = useAppSelector(selectUI);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isPreviewMode = isPreview(location.search);

  const chartItems = React.useMemo(() => {
    if (chart.items) {
      switch (chartTab) {
        case ChartType.TOP100: {
          return chart.items;
        }

        case ChartType.ISRAEL:
        case ChartType.INTERNATIONAL: {
          const lang = chartTab === ChartType.ISRAEL ? 'heb' : 'eng';
          const prevChart = chart.previous?.items.filter((item) => item.lang === lang);

          return chart.items
            .filter((item) => item.lang === lang)
            .map((item, index) => {
              const prevWeekPosition = prevChart?.findIndex((prevChartItem) => prevChartItem.uuid === item.uuid) ?? -1;

              return {
                ...item,
                position: index + 1,
                oldPosition:
                  prevChart !== undefined ? (prevWeekPosition !== -1 ? prevWeekPosition + 1 : -1) : item.oldPosition,
              };
            });
        }

        case ChartType.NEW_SONGS: {
          return chart.items
            .map((item, index) => ({ ...item, position: index + 1, highestPosition: index + 1, oldPosition: -1 }))
            .filter((item) => item.timesInChart === (isPreviewMode && !chart.publishDate ? 0 : 1));
        }

        default:
          return [];
      }
    }
    return [];
  }, [chart, chartTab]);

  const settings = useAppSelector(selectSettings);
  const saveChartText = `האזינו לפלייליסט המלא בספוטיפיי >>`;

  const onItemClick = React.useCallback(
    (index: number) => {
      if (index !== activeItemIndex) {
        setActiveItemIndex(index);
      } else {
        setActiveItemIndex(-1);
      }

      setActiveRecomendedItemIndex(-1);
    },
    [activeItemIndex],
  );

  const onRecomendedItemClick = React.useCallback(
    (index: number) => {
      if (index !== activeRecomendedItemIndex) {
        setActiveRecomendedItemIndex(index);
      } else {
        setActiveRecomendedItemIndex(-1);
      }

      setActiveItemIndex(-1);
    },
    [activeRecomendedItemIndex],
  );

  const onCloseSaveChartMsgBtnClick = () => {
    const observer = tenthItemIntersectionObserverRef.current;
    if (observer) {
      observer.disconnect();
    }

    dispatch(setSaveChartMsgVisible(false));
  };

  const renderRecomendedItems = () => {
    const rowSize = isMobile ? 2 : 4;
    const items = recomendedItems.songs;
    const rows = [];

    for (let i = 0; i < items.length; i += rowSize) {
      rows.push(items.slice(i, i + rowSize));
    }

    return rows.map((row, rowIndex) => {
      const playerId = `row-player-${rowIndex}`;

      return (
        <div className={css.recomendedItemsRow} key={rowIndex}>
          <div className={css.itemsWrap}>
            {row.map((data, itemIndex) => (
              <RecomendedItem
                data={data}
                index={rowIndex * rowSize + itemIndex}
                playerId={playerId}
                isActive={activeRecomendedItemIndex === rowIndex * rowSize + itemIndex}
                onItemClick={onRecomendedItemClick}
                key={data._id.toString()}
              />
            ))}
          </div>
          <div id={playerId} className={css.playerWrap}></div>
        </div>
      );
    });
  };

  React.useEffect(() => {
    if (tenthItemContainer) {
      tenthItemIntersectionObserverRef.current = new IntersectionObserver(
        ([entry]) => {
          const isVisible = entry.intersectionRatio >= 1 || entry.boundingClientRect.y < 0;

          dispatch(setSaveChartMsgVisible(isVisible));
        },
        {
          threshold: [1],
        },
      );

      const observer = tenthItemIntersectionObserverRef.current;
      observer.observe(tenthItemContainer);

      return () => {
        observer.disconnect();
      };
    }
  }, [tenthItemContainer]);

  React.useEffect(() => {
    if (activeItemIndex > -1) {
      setIsAnyItemClicked(true);
    }
  }, [activeItemIndex]);

  React.useEffect(() => {
    const outbrainWrapEl = document.querySelector('.outbrain-wrap');
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsOutbrainVisible(entry.isIntersecting);
      },
      { threshold: 0 },
    );

    if (outbrainWrapEl) {
      observer.observe(outbrainWrapEl);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();
    const onResize = () => {
      setIsMobile(window.innerWidth <= 1023);
    };
    window.addEventListener('resize', onResize, { signal: controller.signal });
    onResize();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className={css.wrapper}>
      <div className={cn(css.sideBanner, ui.saveChartMsgVisible && css.withSaveChartMsg)}>
        <SideBanner />
      </div>
      <div className={cn(css.content, css[chartTab], className)}>
        {chartItems[0]?.position === 1 && (
          <div className={css.firstPlaceItemWrap}>
            <div className={css.contentWrap}>
              {chartItems[0] && (
                <Item
                  data={chartItems[0]}
                  chartType={chartTab}
                  index={0}
                  isActive={activeItemIndex === 0}
                  isPreview={isPreviewMode}
                  isChartPublished={!!chart.publishDate}
                  onItemClick={onItemClick}
                />
              )}
            </div>
          </div>
        )}
        <div className={cn(css.restItemsWrap, chartItems.length === 0 && css.empty)}>
          <div className={css.contentWrap}>
            {chartItems.length ? (
              chartItems?.map((data, index) => {
                if (index === 0 && chartItems[0]?.position === 1) return null;

                return (
                  <React.Fragment key={data._id.toString()}>
                    {(index === 10 || index === 20) && <InnerBanner index={index.toString() as '10' | '20'} />}
                    <Item
                      className={css.item}
                      data={data}
                      chartType={chartTab}
                      index={index}
                      isActive={activeItemIndex === index}
                      isPreview={isPreviewMode}
                      isChartPublished={!!chart.publishDate}
                      containerRef={
                        index === 9 || (chartItems.length <= 10 && index === chartItems.length - 1)
                          ? setTenthItemContainer
                          : undefined
                      }
                      onItemClick={onItemClick}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <>{chartTab === ChartType.NEW_SONGS ? <div className={css.noNewSongs}>{noNewSongsText}</div> : null}</>
            )}
          </div>
        </div>
        {chartTab === ChartType.NEW_SONGS && recomendedItems.songs.length > 0 && (
          <div className={css.recomendedItemsWrap}>
            <div className={css.contentWrap}>
              <div className={css.recomendedItems}>
                <div className={css.recomendedItemsHeader}>
                  <p>{recomendedSongsText}</p>
                </div>
                {renderRecomendedItems()}
              </div>
            </div>
          </div>
        )}
        <div className={css.seoText}>
          <div className={css.contentWrap}>{isAnyItemClicked === false && <p>{settings?.seo?.[chartTab].text}</p>}</div>
        </div>
        <div className={css.footerLogos}>
          <div
            className={cn(
              css.makoLink,
              ui.saveChartMsgVisible && css.withTranslate,
              isAnyItemClicked && css.withoutSEOText,
            )}
          >
            <a href="https://www.mako.co.il/help-5bb6bf4ccf39c110/Article-b7ff18a9de86471026.htm" target="_blank">
              <span>הסדרי נגישות</span>
            </a>
          </div>
          <div className={css.contentWrap}>
            <div className={css.logos}>
              <a className={css.makoLogo} href="https://mako.co.il">
                <img src={makoLogoImg} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div
          className={cn(
            css.rabbiCredits,
            ui.saveChartMsgVisible && css.withTranslate,
            isAnyItemClicked && css.withoutSEOText,
            isOutbrainVisible && css.withOutbrain,
          )}
        >
          <div className={css.rabbiCreditsContent}>
            <p>Developed by</p>
            <a href="https://www.rabbi.co.il" title="רבי אינטראקטיב">
              Rabbi Agency
            </a>
          </div>
        </div>
        <div
          className={cn(css.saveChartMsg, isOutbrainVisible && css.withOutbrain, ui.saveChartMsgVisible && css.open)}
        >
          <button className={css.closeBtn} onClick={onCloseSaveChartMsgBtnClick} />
          <div className={css.contentWrap}>
            <a
              href="https://open.spotify.com/playlist/3Oh3oSaZjfsXcNwSpVMye2"
              onClick={() => analytics.gtag.event('Click to Spotify Playlist')}
              target="_blank"
            >
              {saveChartText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
