import { CDN_URL } from 'src/constants/other';

const FIREBASE_STORAGE_URL = `https://storage.googleapis.com/${process.env.PROJECT_ID}.appspot.com`;

export const cdnUrl = (url?: string) => {
  if (url) {
    return url.replace(FIREBASE_STORAGE_URL, CDN_URL);
  }
  return url;
};
