import { RootState } from '..';

export const selectChartOfTheYear = (state: RootState) => {
  return state.chartOfTheYear;
};

export const selectChartsList = (state: RootState) => {
  return state.settings.chartOfTheYearPublication && state.settings.chartOfTheYearPublication.list;
};

export const selectCurrentYearChart = (state: RootState) => {
  return (
    state.settings.chartOfTheYearPublication &&
    state.settings.chartOfTheYearPublication.list &&
    state.settings.chartOfTheYearPublication.list[0].year
  );
};
