import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';

import appleMusicImg from 'src/assets/images/content/apple-music.png';
import spotifyImg from 'src/assets/images/content/spotify.png';
import youtubeImg from 'src/assets/images/content/youtube.png';

import { Platform } from '@api/constants/charts';
import { IModel } from '@api/models/editor-choice/interface';
import { analytics, cdnUrl } from 'src/utils';
import css from './RecomendedItem.module.scss';

type RecomendedItem = IModel;
type Props = {
  className?: string;
  data: RecomendedItem;
  index: number;
  playerId: string;
  isActive?: boolean;
  containerRef?: React.Ref<HTMLDivElement>;
  onItemClick: (index: number) => unknown;
};

enum Actions {
  LYRICS = 'lyrics',
}

const Item = (props: Props) => {
  const { data, index, isActive, className = '', onItemClick } = props;
  const songLyrics = 'מילות השיר';
  const sponsoredText = 'ממומן';
  const artist = data.artist;
  const title = data.title;
  const coverImg = data.imageUrl;
  const spotifyUrl = data.spotifyUrl?.replace('/track/', '/embed/track/');

  const onClick = () => {
    if (!isActive) {
      analytics.gtag.event(data.sponsored === 'yes' ? 'recommended paid Song Details' : 'recommended Song Details', {
        CUSTOM_PARAMETER: `${title} - ${artist}`,
      });
    }

    onItemClick(index);
  };

  const onPlatformBtnClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const platformCode = e.currentTarget.dataset.id as Platform;
    const url = getIdentifiers(platformCode, data)?.url;

    switch (platformCode) {
      case Platform.APPLE_MUSIC: {
        analytics.gtag.event('recommended on Apple Music', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        break;
      }

      case Platform.SPOTIFY: {
        analytics.gtag.event('recommended on Spotify', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        break;
      }

      case Platform.YOUTUBE: {
        analytics.gtag.event('recommended on YouTube', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        break;
      }

      default:
        break;
    }

    if (url) {
      window.open(url, '_blank');
    }
  };

  const onActionBtnClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id as Actions;

    switch (id) {
      case Actions.LYRICS: {
        analytics.gtag.event('Song Lyrics recommended', { CUSTOM_PARAMETER: `${title} - ${artist}` });
        window.open(data.shironetUrl, '_blank');
        break;
      }

      default:
        break;
    }
  };

  return (
    <div
      className={cn(css.recomendedItem, isActive && css.active, !spotifyUrl && css.disabled, className)}
      onClick={onClick}
      ref={props.containerRef}
    >
      <div className={css.mainContent}>
        <div className={css.cover}>
          {coverImg && <img src={cdnUrl(coverImg)} alt={artist} title={artist} loading="lazy" />}
        </div>
        <div className={css.info}>
          <div className={css.artistAndTitle}>
            <h3 className={css.title}>{title}</h3>
            <h4 className={css.artist}>{artist}</h4>
          </div>
          <div className={css.infoFooter}>
            <div>{data.sponsored === 'yes' && <div className={css.sponsored}>{sponsoredText}</div>}</div>
            <div className={css.crossIcon} />
          </div>
        </div>
      </div>
      {isActive &&
        spotifyUrl &&
        document.getElementById(props.playerId) &&
        ReactDOM.createPortal(
          <div className={css.player}>
            <div className={css.playerEmbed}>
              <iframe
                style={{ borderRadius: '12px' }}
                src={spotifyUrl}
                width="100%"
                height="100%"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </div>
            <div className={css.playerFooter}>
              <div className={css.platforms}>
                {getIdentifiers(Platform.APPLE_MUSIC, data)?.url && (
                  <button
                    className={css.platformAppleMusic}
                    data-id={Platform.APPLE_MUSIC}
                    type="button"
                    onClick={onPlatformBtnClick}
                  >
                    <img src={appleMusicImg} alt="Apple Music" />
                  </button>
                )}
                {getIdentifiers(Platform.SPOTIFY, data)?.url && (
                  <button data-id={Platform.SPOTIFY} type="button" onClick={onPlatformBtnClick}>
                    <img src={spotifyImg} alt="Spotify" />
                  </button>
                )}
                {getIdentifiers(Platform.YOUTUBE, data)?.url && (
                  <button
                    className={css.platformYT}
                    data-id={Platform.YOUTUBE}
                    type="button"
                    onClick={onPlatformBtnClick}
                  >
                    <img src={youtubeImg} alt="YouTube" />
                  </button>
                )}
              </div>
              <div className={css.actions}>
                {data.shironetUrl && (
                  <button data-id={Actions.LYRICS} type="button" onClick={onActionBtnClick}>
                    {songLyrics}
                  </button>
                )}
              </div>
            </div>
          </div>,
          document.getElementById(props.playerId) as HTMLDivElement,
        )}
    </div>
  );
};

const getIdentifiers = (platformCode: Platform, data: RecomendedItem) => {
  if (platformCode === Platform.SPOTIFY && data.spotifyUrl) return { url: data.spotifyUrl };
  if (platformCode === Platform.APPLE_MUSIC && data.appleMusicUrl) return { url: data.appleMusicUrl };
  if (platformCode === Platform.YOUTUBE && data.youtubeUrl) return { url: data.youtubeUrl };
  return null;
};

export default Item;
